import React, {useState, useRef} from 'react';
import {useSelector} from 'react-redux';

import OutletLocationHeader from './OutletLocationHeader';
import OutletTable from '../components/Table';
import CartValueModal from './CartValueModal';

import {editOutletItem, exportOutletData, getOutletData, unblockLocationSession} from 'utils/request/outlet';
import {authChecker, exportToTsvFn, generateOutletCSVTemplate, handleErrorFetch, moneyFormat} from 'utils/utils';
import {OUTLET_TYPE, STORAGE_KEY, USER_ROLES} from 'utils/constants';

import {Divider, Modal, notification, Select, Tag} from 'antd';
import {EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

const CART_VALUE_MODAL_INITIAL_STATE = {
	visible: false,
	minOrderTotal: null,
	maxOrderTotal: null,
	locationId: null,
};

const OutletLocation = () => {
	const containerRef = useRef(null);
	const refetchTableRef = useRef(null);
	const auth = useSelector(state => state.user);

	const [loading, setLoading] = useState(false);
	const [exportQuery, setExportQuery] = useState(null);
	const [cartValueModalConfig, setCartValueModalConfig] = useState(CART_VALUE_MODAL_INITIAL_STATE);
	const [loadingCartValueModal, setLoadingCartValueModal] = useState(false);

	const scrollToTop = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
		}
	};

	const goToEditPage = record => {
		sessionStorage.setItem(STORAGE_KEY.OUTLET_ITEM_DETAIL, JSON.stringify(record));
		window.open('/outlet/location/edit', '_blank');
	};

	const handleUnblock = async record => {
		try {
			if (loading || !record?.id) return;
			setLoading(true);
			const response = await unblockLocationSession(record?.id);
			if (response.success) {
				notification.success({
					description: `${record.label} outlet unblocked successfully`,
				});
			}
		} catch (error) {
			notification.error({
				description: `Failed to unblock ${record.label}, please try again`,
			});
		} finally {
			setLoading(false);
		}
	};

	const openEditCartValueModal = record => {
		setCartValueModalConfig({
			visible: true,
			minOrderTotal: record?.ordersThreshold?.minOrderTotal,
			maxOrderTotal: record?.ordersThreshold?.maxOrderTotal,
			locationId: record?.id,
		});
	};

	const closeEditCartValueModal = () => {
		setCartValueModalConfig(CART_VALUE_MODAL_INITIAL_STATE);
	};

	const handleEditCartValue = async (minOrderTotal, maxOrderTotal) => {
		try {
			setLoadingCartValueModal(true);
			const {locationId} = cartValueModalConfig;
			const response = await editOutletItem({
				locationId,
				ordersThreshold: {
					minOrderTotal,
					maxOrderTotal,
				},
			}, OUTLET_TYPE.LOCATION);

			if (response.success) {
				closeEditCartValueModal();
				refetchTableRef?.current?.();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingCartValueModal(false);
		}
	};

	const tableColumn = [
		{
			title: 'Location ID',
			dataIndex: 'id',
			width: 100,
			key: 'id',
			fixed: 'left',
		},
		{
			title: 'Location Name',
			width: 178,
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'Region',
			width: 146,
			dataIndex: 'region',
			key: 'region',
			search: false,
		},
		{
			title: 'Short Address',
			dataIndex: 'shortAddress',
			key: 'shortAddress',
			width: 219,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 251,
		},
		{
			title: 'Tags',
			dataIndex: 'tags',
			key: 'tags',
			renderFormItem: () => (
				<Select
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			),
			render: tags => (Array.isArray(tags) && tags.length) ? (
				<div className='flex flex-wrap gap-y-2'>
					{tags.map((tag, index) => (
						<Tag
							color='blue'
							className='uppercase'
							key={index}>{tag}
						</Tag>
					))}
				</div>
			) : '-',
		},
		// {
		// 	title: 'POS',
		// 	dataIndex: ['Branch', 'Po', 'label'],
		// 	key: 'posLabel',
		// 	search: false,
		// },
		{
			title: 'Cart Value (Rp)',
			key: 'cartValue',
			search: false,
			render: (_, record) => (
				<div className='flex gap-1'>
					<span>{`Min ${moneyFormat({value: record?.ordersThreshold?.minOrderTotal})} | Max ${moneyFormat({value: record?.ordersThreshold?.maxOrderTotal})}`}</span>
					<EditOutlined
						onClick={() => openEditCartValueModal(record)}
						className='cursor-pointer text-antd-blue-6'
					/>
				</div>
			),
		},
		{
			title: 'Timezone',
			dataIndex: ['timezone', 'label'],
			key: 'timezone',
			search: false,
		},
		{
			title: 'Branch ID',
			dataIndex: ['Branch', 'details', 'branchID'],
			key: 'branchID',
			width: 210,
		},
		{
			title: 'Serving Bulk Order',
			dataIndex: 'allowBulk',
			key: 'allowBulk',
			width: 164,
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.allowBulk ? 'text-green-400' : 'text-red-400'}`
				}>{record.allowBulk ? 'Yes' : 'No'}</div>
			),
		},
		{
			title: 'Active',
			dataIndex: 'isActive',
			key: 'isActive',
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{record.isActive ? 'Yes' : 'No'}</div>
			),
		},
		...(authChecker({
			auth,
			requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
		}) ? [{
				title: 'Action',
				align: 'center',
				key: 'action',
				fixed: 'right',
				search: false,
				width: 107,
				render: (_, record) => (
					<div className='flex gap-2'>
						<span
							onClick={() => goToEditPage(record)}
							className='cursor-pointer text-blue-400'
						>
							Edit
						</span>
						<Divider
							type='vertical'
							className='m-0' />
						<span
							onClick={() => Modal.confirm({
								title: 'Remove start day blocker',
								content: 'You will allow outlet to start day again. Please inform outlet to refresh POS after unblocking',
								icon: <ExclamationCircleOutlined />,
								centered: true,
								cancelText: 'Cancel',
								okText: 'Unblock',
								maskClosable: true,
								onOk: () => handleUnblock(record),
							})}
							className='cursor-pointer text-blue-400'
						>
							Unblock
						</span>
					</div>
				),
			}] : []),
	];

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportOutletData(exportQuery, OUTLET_TYPE.LOCATION),
			tsvTemplate: generateOutletCSVTemplate(OUTLET_TYPE.LOCATION),
		});
	};

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto">
			<CartValueModal
				visible={cartValueModalConfig.visible}
				minOrderTotal={cartValueModalConfig.minOrderTotal}
				maxOrderTotal={cartValueModalConfig.maxOrderTotal}
				submit={handleEditCartValue}
				loading={loadingCartValueModal}
				onClose={closeEditCartValueModal}
			/>
			<OutletLocationHeader
				refetchTableRef={refetchTableRef}
				handleExport={handleExport}
			/>
			<OutletTable
				parentLoading={loading}
				tableColumn={tableColumn}
				fetchFn={getOutletData}
				tableType={OUTLET_TYPE.LOCATION}
				refetchTableRef={refetchTableRef}
				scrollToTop={scrollToTop}
				setExportQuery={setExportQuery}
			/>
		</div>
	);
};

export default OutletLocation;