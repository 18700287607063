import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Checkbox, Input, Modal, notification, Typography} from 'antd';

import {handleErrorFetch} from 'utils/utils';
import {createCampaignName, createProductPush} from 'utils/request/internalOrdering';

import localization from 'localization';
const locale = localization.InternalOrdering.FinanceAddOn.CreateModal;

const CreateModal = ({open, onClose, type, refetchTableRef}) => {
	const isProductPush = type === 'product';

	const [formLabel, setFormLabel] = useState('');
	const [formIsActive, setFormIsActive] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleCreateNew = async () => {
		try {
			if (loading) return;
			const payload = {
				label: formLabel,
				isActive: formIsActive,
			};

			const callFn = isProductPush ? createProductPush : createCampaignName;

			const response = await callFn(payload);
			if (response.success) {
				refetchTableRef.current();
				notification.success({
					message: locale.CreateSucessNotification.message,
					description: locale.CreateSucessNotification.description.replace(
						'{{type}}',
						isProductPush
							? 'product'
							: 'campaign',
					),
				});
				onClose();
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (open) {
			setFormLabel('');
			setFormIsActive(true);
			setLoading(false);
		}
	}, [open]);

	return (
		<Modal
			closable={false}
			title={null}
			centered
			open={open}
			onCancel={onClose}
			onOk={handleCreateNew}
			width={396}
			cancelText={locale.cancel}
			okText={locale.create}
			okButtonProps={{loading, disabled: !formLabel}}
			cancelButtonProps={{disabled: loading}}
		>
			<Typography.Title
				level={5}
				className='m-0 pb-4'>
				{locale.title.replace('{{type}}', isProductPush ? 'product' : 'campaign')}
			</Typography.Title>
			<div className='flex flex-col gap-2'>
				<div>
					{isProductPush ? 'Product Name' : 'Campaign Name'}
				</div>
				<Input
					disabled={loading}
					className='w-full'
					value={formLabel}
					placeholder={locale.placeholder}
					onChange={e => setFormLabel(e.target.value)}
				/>
				<div>{locale.status}</div>
				<div className='flex'>
					<div
						onClick={() => setFormIsActive(!formIsActive)}
						className='flex items-center gap-2 cursor-pointer'>
						<Checkbox
							checked={formIsActive} />
						<div>{locale.active}</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

CreateModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	type: PropTypes.string,
	refetchTableRef: null,
};

CreateModal.defaultProps = {
	open: false,
	onClose: () => {},
	type: null,
	refetchTableRef: PropTypes.object,
};

export default CreateModal;