import React from 'react';

const Home = () => {
	return (
		<div className='bg-white h-full overflow-auto'>
			<div className='flex h-full items-center justify-center font-bold text-7xl'></div>
		</div>
	);
};

export default Home;
