import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
import {removeNullValues} from 'utils/utils';
const baseURL = URLS.ORDERHUB_BASE_URL;

// Watchtower Menu
export const getMenuAvailability = async query => {
	try {
		const {limit, page, isChildMenu, sortBy, sortOrder, search} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			isChildMenu,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-availability-request`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMenuAvailabilityHistory = async query => {
	try {
		const {limit, page, isChildMenu, sortBy, sortOrder, search} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			isChildMenu,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-availability-request/history`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getInactiveReason = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/resto/menu-availability/reason`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editInactiveReason = async (requestId, notes) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/cms/menu-availability-request/update`,
			data: {
				id: requestId,
				notes,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getLinkedMenuRequest = async payload => {
	// payload: {locationId, menuId, menuAvailabilityId}
	// one of menuId or menuAvailabilityId is required
	const {data} = await axios({
		method: 'GET',
		url: `${baseURL}/menu-availability/menu/linked`,
		params: {
			...payload,
		},
	});
	return data;
};

export const downloadMenuAvailabilityReport = async ({createdAtFrom, createdAtTo, isHistory}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-availability-request/${isHistory ? 'history/' : ''}export`,
			params: {createdAtFrom, createdAtTo},
		});
		if (data.success) {
			const blobResponse = await (await fetch(data.data.url)).blob();
			return blobResponse;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

// Watchtower Order
export const getOrderHistory = async query => {
	try {
		const {limit, page, sortBy, sortOrder, search, isInjected, isPos, ...restProps} = query;
		const payload = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
			isInjected,
			...restProps,
		};
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/order${isPos ? '/pos' : ''}/history`,
			data: removeNullValues(payload),
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getOrderHistoryMetric = async query => {
	try {
		const {createdAtFrom,
			createdAtTo,
			acceptedAtFrom,
			acceptedAtTo,
			locationId,
			brandId,
			isPos} = query;
		const params = {
			createdAtFrom,
			createdAtTo,
			acceptedAtFrom,
			acceptedAtTo,
			locationId,
			brandId,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/order${isPos ? '/pos' : ''}/history/summary`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

// Watchtower Promo
export const getPromoCheck = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/wt/promo/job`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPromoCheckResult = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/wt/promo/result`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const downloadOutletRequestReport = async ({createdAtFrom, createdAtTo, isHistory}) => {
	try {
		const endpointPath = isHistory ? 'outlet-requests-history/export' : 'outlet-requests/export';
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/outlet/${endpointPath}`,
			params: {createdAtFrom, createdAtTo},
		});
		if (data.success) {
			const blobResponse = await (await fetch(data.data.url)).blob();
			return blobResponse;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const triggerManualCheckPromo = async () => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/wt/promo/job`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

// Watchtower Promo
export const getOutletRequest = async ({params, isHistory = false}) => {
	try {
		const endpointPath = isHistory ? 'outlet-requests-history' : 'outlet-requests';
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/outlet/${endpointPath}`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getOutletOffReason = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/resto/outlet/reasons`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const voidPosOrder = async payload => {
	// payload = {id, reason}
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/orders/void`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPairingList = async params => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/order/pos/history`,
			data: params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editPairing = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/orders/pair`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};