import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Badge, Divider, Modal, notification, Typography} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';

import {isEmpty} from 'lodash';

import {handleErrorFetch, paramsBooleanHandler, sortDirectionConverter} from 'utils/utils';
import {editCampaignName, editProductPush, getCampaignNameList, getProductPushList} from 'utils/request/internalOrdering';

import EditModal from './EditModal';

import localization from 'localization';
const locale = localization.InternalOrdering.FinanceAddOn.Table;

const editModalInitialState = {
	visible: false,
	record: {},
};

const FinanceAddOnTable = ({type, refetchTableRef}) => {
	const tableRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [editModalConfig, setEditModalConfig] = useState(editModalInitialState);

	const isProductPush = type === 'product';

	const closeEditModal = () => !loading && setEditModalConfig(editModalInitialState);

	const handleEdit = async newLabel =>{
		try {
			if (loading) return;
			setLoading(true);
			const record = editModalConfig?.record;
			const editCallFn = isProductPush ? editProductPush : editCampaignName;
			const response = await editCallFn({
				id: record?.id,
				label: newLabel,
			});
			if (response.success) {
				tableRef?.current?.reload();
				notification.success({
					message: locale.EditSucessNotification.message,
					description: locale.EditSucessNotification.description.replace('{{type}}',
						isProductPush ? 'Product name' : 'Campaign name',
					),
				});
				closeEditModal();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const confirmToggleActive = record => {
		const modalLocale = record?.isActive ? locale.SetInactiveConfirmation : locale.SetActiveConfirmation;

		const label = record?.label;

		Modal.confirm({
			title: modalLocale.title,
			content: modalLocale.content.replace('{{label}}', label),
			icon: <ExclamationCircleOutlined />,
			centered: true,
			cancelText: modalLocale.cancel,
			okText: modalLocale.ok,
			okButtonProps: {danger: record?.isActive},
			maskClosable: true,
			onOk: async () => {
				try {
					if (loading) return;
					setLoading(true);
					const editCallFn = isProductPush ? editProductPush : editCampaignName;
					const response = await editCallFn({
						id: record?.id,
						isActive: !record?.isActive,
					});
					if (response.success) {
						tableRef?.current?.reload();
						const notificationLocale = record?.isActive ? locale.SuccessSetInactiveNotification : locale.SuccessSetActiveNotification;
						notification.success({
							message: notificationLocale.message,
							description: notificationLocale.description.replace('{{label}}', label),
						});
					}
				} catch (error) {
					handleErrorFetch(error);
				} finally {
					setLoading(false);
				}
			},
		});
	};

	const columns = [
		{
			title: 'ID',
			width: 129,
			key: 'id',
			dataIndex: 'id',
			render: id => <Typography.Text copyable>{id}</Typography.Text>,
		},
		{
			title: 'Campaign Name',
			width: 228,
			key: 'label',
			dataIndex: 'label',
		},
		{
			title: 'Status',
			width: 159,
			key: 'isActive',
			dataIndex: 'isActive',
			render: (_, record) => (
				<>
					<Badge
						status={record?.isActive ? 'success' : 'error'}
						text={record?.isActive ? 'Active' : 'Inactive'}
					/>
				</>
			),
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Active',
					status: true,
				},
				inactive: {
					text: 'Inactive',
					status: false,
				},
			},
		},
		{
			title: locale.action,
			width: 197,
			key: 'action',
			search: false,
			fixed: 'right',
			align: 'center',
			render: (_, record) => (
				<div className='flex gap-2 justify-center items-center'>
					<a
						className='text-antd-blue-6'
						onClick={() => setEditModalConfig({
							visible: true,
							record,
						})}
					>{locale.edit}</a>
					<Divider
						className='m-0'
						type='vertical' />
					<a
						onClick={() => confirmToggleActive(record)}
						className={record?.isActive ? 'text-antd-red-6' : 'text-antd-blue-6'}
					>{record?.isActive ? locale.setInactive : locale.setActive}</a>
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter) => {
		try {
			/**
       * Params consist of pagers and table query
       * Sorter consist of column sort
       * Filters consist of column filter
       */
			const sortOrderKey = isEmpty(sorter) ? 'descend' : Object.values?.(sorter)?.[0];
			const sortByKey = isEmpty(sorter) ? 'createdAt' : Object.keys?.(sorter)?.[0];

			['isActive'].forEach(paramsKey => {
				if (params[paramsKey]) paramsBooleanHandler(params, paramsKey);
			});

			const payload = {
				limit: params?.pageSize,
				page: params?.current - 1,
				sortBy: sortByKey,
				sortOrder: sortDirectionConverter(sortOrderKey),
				search: {
					id: params?.id?.toLowerCase?.() || null,
					label: params?.label || null,
					createdBy: params?.createdBy || null,
					isActive: params?.isActive,
				},
			};

			const callFn = isProductPush ? getProductPushList : getCampaignNameList;
			const response = await callFn(payload);

			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		tableRef.current?.reset?.();
		refetchTableRef.current = tableRef?.current?.reload;
	}, [type]);

	return (
		<>
			<EditModal
				open={editModalConfig.visible}
				label={editModalConfig?.record?.label}
				loading={loading}
				onOk={handleEdit}
				onCancel={closeEditModal}
				isProductPush={isProductPush}
			/>
			<ProTable
				loading={loading}
				actionRef={tableRef}
				className="mt-4 ResetSearchTableMargin px-6"
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				onReset={() => tableRef?.current?.reset()}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</>
	);
};

FinanceAddOnTable.defaultProps = {
	type: null,
	refetchTableRef: null,
};

FinanceAddOnTable.propTypes = {
	type: PropTypes.oneOf(['campaign', 'product']),
	refetchTableRef: PropTypes.object,
};

export default FinanceAddOnTable;