import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import cloneDeep from 'lodash/cloneDeep';
import UserPasswordModal from '../../UserPasswordModal';

import {bulkResetAdminUserPassword, getUsers, updateUserDetails} from 'utils/request/user';
import {handleErrorFetch} from 'utils/utils';
import {USER_ROLES} from 'utils/constants';

import {ConfigProvider, Divider, Modal, notification, Popconfirm, Select, Tag, Tooltip} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import enUS from 'antd/lib/locale/en_US';
import ProTable from '@ant-design/pro-table';

import localization from 'localization';
const locale = localization.Admin.UserList.Body;

const {Option} = Select;

const defaultSearch = {
	// menuLabel: null,
	// menuCode: null,
	// menuId: null,
	// tags: null,
	// createdBy: null,
	// updatedBy: null,
	// isActive: null,
};

const defaultQueryParams = {
	search: defaultSearch,
	page: 0,
	limit: 50,
};

const USER_STATUS = {
	ACTIVE: 'Active',
	LOCKED: 'Locked',
};

const UserListBody = () => {
	const navigate = useNavigate();
	const auth = useSelector(state => state.user);
	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const resetRefFlag = useRef(null);

	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [userPasswordModalVisible, setUserPasswordModalVisible] = useState(false);
	const [resetUserDetail, setResetUserDetail] = useState({});

	const {search, page, limit} = queryParams;

	// Function

	const fetchTableData = async (config = {
		reset: false,
	}) => {
		try {
			setLoading(true);
			const {reset} = config;
			let tableDataResponse;
			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				tableDataResponse = await getUsers({...defaultQueryParams, limit}, 'admin');
			} else {
				tableDataResponse = await getUsers(queryParams, 'admin');
			}
			if (tableDataResponse.success) setTableData(tableDataResponse.data);
			else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const handleResetPassword = async user => {
		try {
			setLoading(true);
			const response = await bulkResetAdminUserPassword({userId: [user.id]});
			if (response.success) {
				setResetUserDetail({
					username: response.data.users[0].username,
					password: response.data.users[0].password,
				});
			} else throw {};
			setUserPasswordModalVisible(true);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleLockUnlockUser = user => {
		// Action Lock or Unlock
		const isLock = user.isLocked;

		const actionLocale = isLock ? locale.UnlockAction : locale.LockAction;
		Modal.confirm({
			title: actionLocale.title,
			icon: <ExclamationCircleOutlined />,
			content: actionLocale.content.replace('{{username}}', user.username),
			centered: true,
			cancelText: actionLocale.cancel,
			okText: actionLocale.ok,
			okButtonProps: {danger: isLock ? false : true},
			onOk: async () => {
				try {
					const payload = {
						id: user.id,
						isLocked: isLock ? false : true,
					};
					const response = await updateUserDetails(payload);
					if (response.success) {
						fetchTableData();
						notification.success({
							message: actionLocale.Success.message,
							description: actionLocale.Success.description,
						});
					}
				} catch (error) {
					handleErrorFetch(error);
				} finally {
					setLoading(false);
				}
			},
		  });
	};

	const handleEditUser = user => {
		navigate('/admin/user-list/edit', {
			state: {
				id: user.id,
				username: user.username,
				roles: user.details.roles,
				name: user.name,
				email: user.email,
				phoneNumber: user.phoneNumber,
			},
		});
	};

	// Config

	const tableColumn = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: 50,
			search: false,
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			width: 300,
		},
		// {
		// 	title: 'Email',
		// 	dataIndex: 'email',
		// 	key: 'email',
		// },
		{
			title: 'Roles',
			dataIndex: ['details', 'roles'],
			key: 'roles',
			width: 350,
			renderFormItem: () => (
				<Select
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			),
			render: roles => (
				(
					<div className='flex'>{roles.slice(0, 2).map(role => (<Tag key={role}>{role}</Tag>))}{
						roles.slice(2).length ? (
							<>
								<Tooltip title={roles.slice(2).join(', ')}>
									<div className='
									items-center justify-center px-2 py-1 text-sm leading-none rounded-full
									bg-gray-100 text-gray-500 font-medium cursor-pointer
									'>
										+{roles.slice(2).length}
									</div>
								</Tooltip>
							</>
						) : null
					}</div>
				)
			),
		},
		{
			title: 'Status',
			key: 'status',
			width: 100,
			renderFormItem: () => (
				<Select placeholder='Please select'>
					{
						Object.values(USER_STATUS).map(status => (
							<Option
								key={status}
								value={status}>{status}</Option>
						))
					}
				</Select>
			),
			render: (_, item) => (
				<div>
					{
						item.isActive
							?
							item.isLocked
								? <Tag color="red">Locked</Tag>
								: <Tag color="green">Active</Tag>
							: <Tag>Inactive</Tag>
					}
				</div>
			),
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			fixed: 'right',
			search: false,
			width: 250,
			render: (_, item) => {
				let showAction = true;

				// If current user is not superadmin, show no action toward superadmin
				if (
					item.details.roles.includes(USER_ROLES.SUPERADMIN) && !auth.roles.includes(USER_ROLES.SUPERADMIN)
				) showAction = false;

				return showAction
					? (
						<div
							className='flex items-center justify-start gap-2 text-antd-blue-6'>
							<Popconfirm
								placement='topRight'
								onConfirm={() => handleResetPassword(item)}
								title={locale.resetPasswordConfirmation} >
								<a>Reset Password</a>
							</Popconfirm>
							<Divider type='vertical' />
							<a
								className='text-antd-blue-6'
								onClick={() => handleEditUser(item)}>Edit</a>
							<Divider type='vertical' />
							<a
								className='text-antd-blue-6'
								onClick={() => handleLockUnlockUser(item)} >{item.isLocked ? 'Unlock' : 'Lock'}</a>
						</div>
					)
					: '-';
			},
		},
	];

	// Lifecycle

	useEffect(() => {
		if (resetRefFlag.current) return;
		(async () => await fetchTableData())();
	}, [search, page, limit]);

	return (
		<div>
			<UserPasswordModal
				visible={userPasswordModalVisible}
				userDetail={resetUserDetail}
				onClose={() => {
					setUserPasswordModalVisible(false);
					setResetUserDetail({});
				}}
				isReset
			/>
			<ConfigProvider locale={enUS}>
				<ProTable
					rowKey='id'
					id='admin-user-table'
					loading={loading}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: tableData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);

						if (params.status === USER_STATUS.ACTIVE) params.isActive = true;
						if (params.status === USER_STATUS.LOCKED) params.isLocked = true;
						delete params.status;

						if (params?.roles?.length) {
							params.roles = params.roles.join(',');
						}

						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					search={{
						layout: 'vertical',
					}}
					options={false}
					dataSource={tableData?.rows}
					columns={tableColumn}
					onReset={() => fetchTableData({reset: true})}
				/>
			</ConfigProvider>
		</div>
	);
};

export default UserListBody;