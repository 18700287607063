import React from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';
import {Checkbox, DatePicker, Input, Typography} from 'antd';
import moment from 'moment';


const CampaignModalDefault = ({
	isEdit, loading, inputVal,
	setInputVal, campaignForKolSeeding, setCampaignForKolSeeding,
	campaignEndDate, setCampaignEndDate,
}) => {
	const locale = isEdit
		? localization.InternalOrdering.OrderCreator.EditCampaignModal
		: localization.InternalOrdering.OrderCreator.NewCampaignModal;

	const disableDates = current => {
		const tomorrow = moment().add(1, 'day').startOf('day');
		const oneMonthAhead = moment().add(2, 'month').endOf('day');

		return current.isBefore(tomorrow) || current.isAfter(oneMonthAhead);	};

	return (
		<div>
			<Typography.Title
				className='m-0'
				level={5}>{locale.title}</Typography.Title>
			<div className='flex flex-col gap-4'>
				<div className='flex flex-col gap-2'>
					<div>{locale.subtitle}</div>
					<Input
						disabled={loading}
						value={inputVal}
						onChange={e => setInputVal(e.target.value)}
						className='max-w-xs'
						placeholder={locale.inputPlaceholder}
					/>
				</div>
				{
					!isEdit && (
						<div
							className='space-x-2'>
							<Checkbox
								onClick={() => setCampaignForKolSeeding(!campaignForKolSeeding)}
								checked={campaignForKolSeeding}
							>
								<span>Create campaign for KOL Seeding</span>
							</Checkbox>
						</div>
					)
				}
				{
					campaignForKolSeeding && (
						<div className='flex flex-col gap-2'>
							<div>Campaign End Date</div>
							<DatePicker
								value={campaignEndDate}
								onChange={val => setCampaignEndDate(val)}
								className='max-w-xs'
								disabledDate={disableDates}
								showToday={false}
							/>
						</div>
					)
				}
			</div>
		</div>
	);
};

CampaignModalDefault.defaultProps = {
	loading: false,
	isEdit: false,
	inputVal: '',
	setInputVal: () => null,
	campaignForKolSeeding: false,
	setCampaignForKolSeeding: () => null,
	campaignEndDate: null,
	setCampaignEndDate: () => null,
};

CampaignModalDefault.propTypes = {
	loading: PropTypes.bool,
	isEdit: PropTypes.bool,
	inputVal: PropTypes.string,
	setInputVal: PropTypes.func,
	campaignForKolSeeding: PropTypes.bool,
	setCampaignForKolSeeding: PropTypes.func,
	campaignEndDate: PropTypes.object,
	setCampaignEndDate: PropTypes.func,
};

export default CampaignModalDefault;