import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Input, Modal, Typography} from 'antd';

import localization from 'localization';
const locale = localization.InternalOrdering.FinanceAddOn.Table.EditModal;

const EditModal = ({open, label, onOk, onCancel, isProductPush, loading}) => {
	const [formLabel, setFormLabel] = useState('');

	useEffect(() => {
		if (open) {
			setFormLabel(label);
		}

		return () => {
			setFormLabel('');
		};
	}, [open]);

	return (
		<Modal
			closable={false}
			title={null}
			centered
			open={open}
			onCancel={onCancel}
			onOk={() => onOk(formLabel)}
			width={396}
			cancelText={locale.cancel}
			okText={locale.save}
			okButtonProps={{loading}}
			cancelButtonProps={{disabled: loading}}
		>
			<Typography.Title
				level={5}
				className='m-0 pb-4'>{locale.title}</Typography.Title>
			<div className='flex flex-col gap-2'>
				<div>
					{isProductPush ? 'Product Name' : 'Campaign Name'}
				</div>
				<Input
					disabled={loading}
					placeholder={locale.placeholder}
					className='w-full'
					value={formLabel}
					onChange={e => setFormLabel(e.target.value)}
				/>
			</div>
		</Modal>
	);
};

EditModal.propTypes = {
	open: PropTypes.bool,
	label: PropTypes.string,
	onOk: PropTypes.func,
	onCancel: PropTypes.func,
	isProductPush: PropTypes.bool,
	loading: PropTypes.bool,
};

EditModal.defaultProps = {
	open: false,
	label: '',
	onOk: () => {},
	onCancel: () => {},
	isProductPush: false,
	loading: false,
};

export default EditModal;