import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import './MenuDrawer.less';

import ProtectedComponent from 'components/ProtectedComponent';

import OptionGroupDrawer from './OptionGroupDrawer';
import OptionGroupItem from './OptionGroupItem';

import cloneDeep from 'lodash/cloneDeep';

import {DragDropContext} from 'react-beautiful-dnd';
import {Droppable} from 'react-beautiful-dnd';
import {Draggable} from 'react-beautiful-dnd';

import {CloseOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined} from '@ant-design/icons';
import {Button, Drawer, Empty, Input, Select, Upload, Form, InputNumber, Spin, Typography, Alert, Modal, notification, Radio} from 'antd';
const {Text, Link} = Typography;
const {TextArea} = Input;
const {Option} = Select;

import {CLOUDINARY_DIR, IMAGE_SIZE, USER_ROLES} from 'utils/constants';
import {uploadImage} from 'utils/cloudinary';
import {getStationLabel, handleErrorFetch, inputNumberCurrencyFormatter} from 'utils/utils';

import localization from 'localization';
import {isEmpty, setWith} from 'lodash';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.MenuDrawer;

const KDS_NAME_CONFIG = {
	MASTERLIST: 'masterlist',
	CURRENT_TEMPLATE: 'currentTemplate',
};

const MenuDrawer = ({
	visible, onClose, menuDataSource, getCategoryStats, addMenuToCategory, isEdit, pickMenu,
	setIsEdit, viewOnly, isMenuStructure, editMapping, handleCopyHash,
}) => {
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState(menuDataSource.imageUrl);
	const [menuOptionGroup, setMenuOptionGroup] = useState(
		menuDataSource.modifierGroups || menuDataSource.optionGroup || menuDataSource.optionGroups || [],
	);
	const [duplicateChildIds, setDuplicateChildIds] = useState([]);

	const [form] = Form.useForm();

	const containerRef = useRef(null);

	const [optionGroupDrawerVisible, setOptionGroupDrawerVisible] = useState(false);

	const [kdsNameConfig, setKdsNameConfig] = useState(null);

	const handleAddMenu = e => {
		e.optionGroups = menuOptionGroup.map(el => {
			const output = {
				optionGroupId: el.id || el.optionGroupId,
				optionGroupLabel: el.label || el.optionGroupLabel,
				tags: el.tags,
				minSelection: el.minSelection,
				maxSelection: el.maxSelection,
				menus: (el?.details?.menus || el?.menus).map(childMenu => {
					return {
						menuId: childMenu.menuId,
						menuLabel: childMenu.menuLabel,
						menuPrice: childMenu.price || childMenu.menuPrice || 0,
					};
				}),
			};

			if (!isEmpty(el?.packaging)) output.packaging = el?.packaging;

			return output;
		});

		e.menuStation = menuDataSource?.station || menuDataSource?.menuStation || '';
		e.menuId = menuDataSource.id || menuDataSource.menuId;
		e.menuTags = menuDataSource.menuTags || [];
		e.tags = menuDataSource.tags || [];
		if (imageUrl) {
			e.imageUrl = imageUrl;
		}

		const payload = {
			...e,
		};

		if (isEdit) {
			payload.locationFilter = menuDataSource.locationFilter;
		} else {
			payload.locationFilter = {
				type: 'default',
				locationIds: [-1],
			};
		}

		const cleanKdsName = (payload?.kdsName || '')?.trim?.();
		if (cleanKdsName) {
			payload.kdsName = cleanKdsName;
		} else delete payload.kdsName;

		addMenuToCategory(payload);

		const notificationLocale = isEdit ? locale.Notification.Edit : locale.Notification.Add;
		if (isEdit) setIsEdit(false);

		notification.open({
			message: notificationLocale.message,
			description: notificationLocale.description,
			type: 'success',
		});
	};

	const beforeAddMenu = e => {
		if (kdsNameConfig === KDS_NAME_CONFIG.CURRENT_TEMPLATE && !e.kdsName) {
			notification.error({
				message: locale.Notification.InvalidKdsName.message,
				description: locale.Notification.InvalidKdsName.description,
			});
		} else {
			const confirmationLocale = isEdit ? locale.Confirmation.Edit : locale.Confirmation.Add;
			Modal.confirm({
				title: confirmationLocale.title,
				content: confirmationLocale.content,
				centered: true,
				maskClosable: true,
				onOk: () => handleAddMenu(e),
				okText: confirmationLocale.okText,
				cancelText: confirmationLocale.cancelText,
			});
		}
	};

	const beforeClose = () => {
		if (isMenuStructure) {
			onClose();
		} else {
			Modal.confirm({
				title: locale.Confirmation.CancelEdit.title,
				content: locale.Confirmation.CancelEdit.content,
				centered: true,
				maskClosable: true,
				onCancel: () => {
					if (isEdit) setIsEdit(false);
					onClose({clearData: true});
				},
				okText: locale.Confirmation.CancelEdit.okText,
				cancelText: locale.Confirmation.CancelEdit.cancelText,
			});
		}
	};

	const handleChangeOptionGroup = incomingOptionGroup => {
		const filteredArr = incomingOptionGroup.reduce((acc, current) => {
			const x = acc.find(item => item.optionGroupId === current.optionGroupId);
			if (!x) {
				return acc.concat([current]);
			} else {
				return acc;
			}
		}, []);

		setMenuOptionGroup(filteredArr);
	};

	const handleDragEnd = result => {
		if (!result.destination) return;

		const items = cloneDeep(menuOptionGroup);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		setMenuOptionGroup(items);
	};

	const handleChange = async image => {
		try {
			setLoading(true);
			if (image.file.size && image.file?.size > IMAGE_SIZE.MAX_IMAGE_SIZE) {
				// TODO Error alert
				return;
			}
			const uploadResponse = await uploadImage(image.file, CLOUDINARY_DIR.MENU_IMAGE);
			const uploadedImageUrl = uploadResponse.secure_url || uploadResponse.url;
			setImageUrl(uploadedImageUrl);
		} catch (error) {
			handleErrorFetch(error);
		}	finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!visible) return;
		form.setFieldsValue({
			menuLabel: menuDataSource.menuLabel,
			menuPrice: menuDataSource.price || menuDataSource.menuPrice || 0,
			description: menuDataSource.description,
			categoryId: getCategoryStats()?.activeCategory?.categoryId,
			kdsName: menuDataSource.kdsName || null,
		});
		const tempMenuOptionGroup = (menuDataSource.modifierGroups || menuDataSource.optionGroup || menuDataSource.optionGroups || []);
		// Filter duplicate option group
		const filteredMenuOptionGroup = tempMenuOptionGroup.filter((v, i, a) => a.findIndex(t => (t.optionGroupId === v.optionGroupId)) === i);
		setKdsNameConfig(menuDataSource?.kdsName ? KDS_NAME_CONFIG.CURRENT_TEMPLATE : KDS_NAME_CONFIG.MASTERLIST);
		setMenuOptionGroup(filteredMenuOptionGroup);
		setImageUrl(menuDataSource.imageUrl);
		setLoading(false);
		return () => {
			if (containerRef.current) {
				containerRef.current.scrollTop = 0;
			}
		};
	}, [visible]);

	useEffect(() => {
		if (!visible) return;
		const tempDuplicateIds = {};

		menuOptionGroup.map(optionGroupI => {
			menuOptionGroup.forEach(optionGroupJ => {
				if (optionGroupI.optionGroupId !== optionGroupJ.optionGroupId) {
					optionGroupI.menus.forEach(childMenuI => {
						if (optionGroupJ.menus.some(childMenuJ => Number(childMenuJ.menuId) === Number(childMenuI.menuId))) {
							setWith(tempDuplicateIds, [optionGroupI.optionGroupId, childMenuI.menuId], true, Object);
						}
					});
				}
			});
		});

		setDuplicateChildIds(tempDuplicateIds);
	}, [menuOptionGroup]);

	return (
		<Drawer
			title={locale.menuDetail}
			visible={visible}
			closable={false}
			extra={isMenuStructure && (
				<CloseOutlined
					onClick={() => onClose({clearData: true})}
					className='cursor-pointer' />
			)}
			maskClosable={viewOnly ? true : false}
			onClose={() => onClose({clearData: true})}
			width={515}
			className="MenuDrawer"
		>
			<OptionGroupDrawer
				menuOptionGroup={menuOptionGroup}
				handleChangeOptionGroup={handleChangeOptionGroup}
				visible={optionGroupDrawerVisible}
				onClose={() => {
					setOptionGroupDrawerVisible(false);
				}}
			/>
			<Form
				form={form}
				layout="vertical"
				name="menuDrawerForm"
				className='flex flex-col h-full'
				scrollToFirstError
				onFinish={e => !loading && beforeAddMenu(e)}
				onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
				requiredMark={false}
			>
				<div
					ref={containerRef}
					className='flex-1 bg-red overflow-auto'>
					{/* Top Section */}
					<div className='flex flex-col px-4 pt-8 pb-6'>
						<div className='flex items-center gap-6'>
							<Upload
								accept="image/*"
								multiple={false}
								maxCount={1}
								disabled={viewOnly}
								className="w-32 h-32 relative border border-antd-netural-5"
								showUploadList={false}
								onChange={handleChange}
								beforeUpload={() => false}
							>
								{
									loading && (
										<div className='bg-overlay-60 absolute inset-0 flex items-start justify-center'>
											<Spin className='mt-auto mb-auto' />
										</div>
									)
								}
								{
									imageUrl
										? (
											<>
												{
													!viewOnly && (
														<div className='
															absolute inset-0 h-full w-full flex items-center justify-center
															bg-overlay-40 text-white transition-opacity duration-300
															opacity-0 hover:opacity-100
														'>
															{locale.changePicture}
														</div>
													)
												}
												<img
													className='border border-antd-netural-5 w-32 h-32 object-contain'
													style={{width: '100%'}}
													src={imageUrl}
												/>
											</>
										)
										: (
											<div
												className='w-32 h-32 bg-antd-netural-4 flex flex-col gap-2 items-center justify-center text-gray-500'>
												<PlusOutlined />
												{locale.upload}
											</div>
										)
								}
							</Upload>
							<div className='flex flex-col'>
								<Text type="secondary">{`${getStationLabel((menuDataSource.station || menuDataSource.menuStation))} · ${menuDataSource.id || menuDataSource.menuId}`}</Text>
								<Text strong>{menuDataSource.menuLabel}</Text>
								{
									!viewOnly && (
										<Link
											onClick={pickMenu}
											className='mt-1'>{locale.changeMenu}</Link>
									)
								}
							</div>
						</div>

						{!viewOnly && (
							<Alert
								className='mt-6'
								message={locale.infoAlert}
								type="info"
								showIcon />
						)}

						{/* Menu Name & Menu Price */}
						<div className='flex gap-4 mt-6'>
							<div className='flex flex-1 flex-col gap-2'>
								<Form.Item
									label={locale.menuNameLabel}
									name="menuLabel"
								>
									<Input
										disabled={viewOnly}
										placeholder={locale.menuNamePlaceholder}
									/>
								</Form.Item>
							</div>
							<div className='flex flex-col gap-2'>
								<Form.Item
									label={locale.menuPriceLabel}
									name="menuPrice"
								>
									<InputNumber
										formatter={inputNumberCurrencyFormatter}
										type='tel'
										min={0}
										max={2000000}
										disabled={viewOnly}
										addonBefore={locale.menuPriceCurrency}
										placeholder={locale.menuPricePlaceholder}
									/>
								</Form.Item>
							</div>
						</div>
						{/* End of Menu Name & Menu Price */}

						{/* Menu Description */}
						<div className='flex flex-col gap-2'>
							<Form.Item
								label={locale.menuDescriptionLabel}
								name="description"
							>
								<TextArea
									disabled={viewOnly}
									showCount
									maxLength={250}
									placeholder={!viewOnly && locale.menuDescriptionPlaceholder}
								/>
							</Form.Item>
						</div>
						{/* End of Menu Description */}

						{/* Menu Category */}
						{
							!isMenuStructure && (
								<div className='flex flex-col gap-2'>
									<Form.Item
										label={locale.menuCategoryLabel}
										name="categoryId"
									>
										<Select
											disabled={viewOnly}
											placeholder={locale.menuCategoryPlaceholder}>
											{
												getCategoryStats()?.categories?.map(category => (
													<Option
														value={category.categoryId}
														key={category.categoryId}>{category.categoryLabel}</Option>
												))
											}
										</Select>
									</Form.Item>
								</div>
							)
						}
						{/* End of Menu Category */}

						{/* KDS Name Source */}
						<div className='flex flex-col gap-2'>
							<div>{locale.kdsName}</div>
							<Radio.Group
								disabled={viewOnly}
								value={kdsNameConfig}
								onChange={e => setKdsNameConfig(e.target.value)}>
								<Radio value={KDS_NAME_CONFIG.MASTERLIST}>{locale.masterlist}</Radio>
								<Radio value={KDS_NAME_CONFIG.CURRENT_TEMPLATE}>{locale.currentTemplate}</Radio>
							</Radio.Group>
							{
								kdsNameConfig === KDS_NAME_CONFIG.CURRENT_TEMPLATE
									? (
										<div>
											<Form.Item
												name="kdsName"
												className='m-0'
											>
												<Input disabled={viewOnly} />
											</Form.Item>
											<Text type='secondary' >{locale.currentTemplateInfo}</Text>
										</div>

									)
									: null
							}
						</div>
						{/* End of KDS Name Source */}

					</div>

					{/* Option Group */}
					<div>
						<div className='px-6 py-4 bg-gray-100 flex justify-between items-center'>
							<div className='font-medium text-sm'>
								{locale.optionGroupLabel}
							</div>
							{!viewOnly && menuOptionGroup.length ? (
								<div
									onClick={() => setOptionGroupDrawerVisible(true)}
									className='text-xs leading-5 font-normal bg-white px-2 rounded-sm cursor-pointer flex gap-2 items-center'>
									<EditOutlined />
									{locale.edit}
								</div>
							) : null}
						</div>
						{
							menuOptionGroup.length
								? (
									<DragDropContext onDragEnd={handleDragEnd}>
										<Droppable droppableId='optionGroups'>
											{provided => (
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
												>
													{
														menuOptionGroup.map((optionGroup, index) => (
															<Draggable
																key={`id-${optionGroup.optionGroupId}`}
																draggableId={`id-${optionGroup.optionGroupId}`}
																isDragDisabled={viewOnly}
																index={index}>
																{provided => (
																	<OptionGroupItem
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		dragHandleProps={provided.dragHandleProps}
																		optionGroup={optionGroup}
																		key={menuDataSource.childMenuId}
																		viewOnly={viewOnly}
																		duplicateChildsId={duplicateChildIds[optionGroup.optionGroupId]}
																	/>
																)}
															</Draggable>
														))
													}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								)
								: (
									<div className='pb-4'>
										<Empty
											className='flex flex-col items-center justify-center pt-4'
											image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
											imageStyle={{
												height: 60,
											}}
											description={locale.optionGroupEmpty}
										>
											{
												!viewOnly && (
													<Button
														onClick={() => setOptionGroupDrawerVisible(true)}
														type="primary">{locale.addOptionGroup}</Button>
												)
											}
										</Empty>
									</div>
								)
						}
					</div>
					{/* End of Option Group */}
				</div>
				{
					isEmpty(duplicateChildIds)
						? null
						: (
							<Alert
								icon={<ExclamationCircleFilled />}
								type="error"
								message={locale.duplicateChildMenuAlert}
								banner />
						)
				}
				<ProtectedComponent
					when={u => u.roles.some(role => [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR].includes(role))}>
					<div
						className='p-6 border-t w-full mt-auto flex gap-4'
					>
						{
							viewOnly
								? isMenuStructure
									? (
										<>
											<Button
												loading={loading}
												onClick={handleCopyHash}
												className='w-44'
											>{locale.copyHash}</Button>
											<Button
												loading={loading}
												onClick={editMapping}
												type='primary'
												className='flex-1'
											>{locale.editMapping}</Button>
										</>
									)
									: (
										<Button
											onClick={() => onClose({clearData: true})}
											className='flex-1'
											type='primary'>{locale.close}</Button>
									)
								: (
									<>
										<Button
											loading={loading}
											onClick={beforeClose}
										>{locale.cancel}</Button>
										<Button
											disabled={!isEmpty(duplicateChildIds)}
											loading={loading}
											htmlType={'submit'}
											type='primary'
											className='flex-1'
										>{isEdit ? locale.save : locale.addMenuCta}</Button>
									</>
								)
						}
					</div>
				</ProtectedComponent>
			</Form>
		</Drawer>
	);
};

MenuDrawer.defaultProps = {
	visible: false,
	isEdit: false,
	setIsEdit: () => null,
	onClose: () => null,
	menuDataSource: {},
	getCategoryStats: () => null,
	addMenuToCategory: () => null,
	pickMenu: () => null,
	viewOnly: false,
	isMenuStructure: false,
	editMapping: () => null,
	handleCopyHash: () => null,
};

MenuDrawer.propTypes = {
	visible: false,
	isEdit: PropTypes.bool,
	setIsEdit: PropTypes.func,
	onClose: PropTypes.func,
	menuDataSource: PropTypes.object,
	getCategoryStats: PropTypes.func,
	addMenuToCategory: PropTypes.func,
	pickMenu: PropTypes.func,
	viewOnly: PropTypes.bool,
	isMenuStructure: PropTypes.bool,
	editMapping: PropTypes.func,
	handleCopyHash: PropTypes.func,
};

export default MenuDrawer;