import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';
import {DatePicker, Select} from 'antd';

import moment from 'moment';
import {handleErrorFetch} from 'utils/utils';
import {getCampaignNameList, getProductPushList} from 'utils/request/internalOrdering';
import {debounce} from 'lodash';
import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';

const CampaignModalFinanceDetail = ({getter, setter, isActive}) => {
	const locale = localization.InternalOrdering.OrderCreator.FinanceDetail;

	const [brandList, setBrandList] = useState([]);
	const [productPushList, setProductPushList] = useState([]);
	const [campaignNameList, setCampaignNameList] = useState([]);
	const [loadingSearchCampaignName, setLoadingSearchCampaignName] = useState(false);
	const [loadingSearchProductPush, setLoadingSearchProductPush] = useState(false);

	const fetchBrands = async () => {
		try {
			const response = await getOutletData({
				search: {limit: 0, isActive: true},
			}, OUTLET_TYPE.BRAND);
			if (response.success) {
				setBrandList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const fetchProductPush = async val => {
		try {
			if (loadingSearchProductPush) return;
			setLoadingSearchProductPush(true);
			const response = await getProductPushList({limit: 5, search: {label: val}});
			if (response.success) {
				setProductPushList(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingSearchProductPush(false);
		}
	};

	const searchProductPush = useCallback(
		debounce(val => fetchProductPush(val), 500),
		[],
	);

	const fetchCampaignName = async val => {
		try {
			if (loadingSearchCampaignName) return;
			setLoadingSearchCampaignName(true);
			const response = await getCampaignNameList({limit: 5, search: {label: val}});
			if (response.success) {
				setCampaignNameList(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingSearchCampaignName(false);
		}
	};

	const searchCampaignName = useCallback(
		debounce(val => fetchCampaignName(val), 500),
		[],
	);

	const fetchFinanceDetails = async () => {
		try {
			await fetchBrands();
			await fetchProductPush();
			await fetchCampaignName();
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		if (!isActive) return;
		fetchFinanceDetails();
	}, [isActive]);

	return (
		<div className='flex flex-col gap-6 w-full'>
			{/* Campaign Name */}
			<div className='flex flex-col gap-2'>
				<div>{locale.campaignName}</div>
				<Select
					showSearch
					loading={loadingSearchCampaignName}
					placeholder={locale.campaignNamePlaceholder}
					optionFilterProp="label"
					value={getter.campaignNameId}
					onChange={val => setter(state => ({
						...state,
						campaignNameId: val,
					}))}
					onSearch={searchCampaignName}
					options={campaignNameList?.map?.(campaignName => {
						return {
							value: campaignName.id,
							label: campaignName.label,
						};
					})}
				/>
			</div>

			{/* Brand */}
			<div className='flex flex-col gap-2'>
				<div>{locale.brand}</div>
				<Select
					showSearch
					placeholder={locale.brandPlaceholder}
					optionFilterProp="label"
					value={getter.brandId}
					onChange={val => setter(state => ({
						...state,
						brandId: val,
					}))}
					options={brandList?.map?.(brand => {
						return {
							value: brand.id,
							label: brand.label,
						};
					})}
				/>
			</div>

			{/* Product Push */}
			<div className='flex flex-col gap-2'>
				<div>{locale.productPush}</div>
				<Select
					showSearch
					loading={loadingSearchProductPush}
					placeholder={locale.productPushPlaceholder}
					optionFilterProp="label"
					value={getter.productPushId}
					onChange={val => setter(state => ({
						...state,
						productPushId: val,
					}))}
					onSearch={searchProductPush}
					options={productPushList?.map?.(productPush => {
						return {
							value: productPush.id,
							label: productPush.label,
						};
					})}
				/>
			</div>

			{/* Period */}
			<div className='flex flex-col gap-2'>
				<div>{locale.period}</div>
				<DatePicker
					format='YYYY-MM'
					picker='month'
					placeholder={locale.periodPlaceholder}
					value={
						moment.isMoment(getter.period)
							? moment(getter.period, 'YYYY-MM')
							: null
					}
					onChange={val => {
						let tempVal = null;
						if (moment.isMoment(val)) {
							tempVal = val;
						}

						setter(state => ({
							...state,
							period: tempVal,
						}));
					}}
				/>
			</div>
		</div>
	);
};

CampaignModalFinanceDetail.defaultProps = {
	getter: {},
	setter: () => null,
	isActive: false,
};

CampaignModalFinanceDetail.propTypes = {
	getter: PropTypes.object,
	setter: PropTypes.func,
	isActive: PropTypes.bool,
};

export default CampaignModalFinanceDetail;