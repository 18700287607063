import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import './MenuTemplateTable.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort, faSortDown, faSortUp} from '@fortawesome/pro-solid-svg-icons';

import ProTable from '@ant-design/pro-table';
import enUS from 'antd/lib/locale/en_US';
import {ConfigProvider, notification, Popconfirm, Typography, Divider, Dropdown, Menu, Modal} from 'antd';
const {Paragraph} = Typography;

import cloneDeep from 'lodash/cloneDeep';
import usePrevious from 'hooks/usePrevious';

import {authChecker, dateFormat, handleErrorFetch} from 'utils/utils';
import {deleteMenuTemplate, deleteMenuTemplateDraft, getMasterlistData, getMenuTemplateDraft, unarchiveTemplate} from 'utils/request/masterlist';
import {getMenuTemplateDetail} from 'utils/request/salesChannel';

import {STORAGE_KEY, USER_ROLES} from 'utils/constants';
import {DownOutlined} from '@ant-design/icons';
const EDITOR_ROLES = [USER_ROLES.MENU_TEMPLATE.ADMIN, USER_ROLES.MENU_TEMPLATE.EDITOR];

let highlightNewDataTimeout = null;

const MenuTemplateTable = ({
	isArchived, activeTabs, tabsKey, exportCSV, refetchTableRef, setLatestUpdateInfo, openCreateModal,
}) => {
	const defaultSearch = {
		label: null,
		templateId: null,
		createdBy: null,
		isDeleted: isArchived ? true : false,
	};

	const defaultQueryParams = {
		search: defaultSearch,
		page: 0,
		sortBy: isArchived ? 'deletedAt' : 'createdAt',
		sortOrder: 'desc',
		limit: 10,
	};

	const auth = useSelector(state => state.user);

	const [loading, setLoading] = useState(false);
	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const [menuTemplateData, setMenuTemplateData] = useState([]);
	const previousMenuTemplateData = usePrevious(menuTemplateData);
	const [highlightNewData, setHighlightNewData] = useState(false);

	const resetRefFlag = useRef(false);

	const {search, page, sortOrder, limit} = queryParams;

	const isDraft = activeTabs === 'draft';

	// Function Section

	const fetchTableData = async (config = {
		reset: false,
		highlightNewData: false,
		captureLatestUpdateTime: false,
	}) => {
		try {
			const {reset, highlightNewData, captureLatestUpdateTime} = config;
			setLoading(true);
			let menuTemplateDataResponse;

			let fetchFn = getMasterlistData;
			if (isDraft) {
				fetchFn = getMenuTemplateDraft;
				delete defaultQueryParams.search.isDeleted;
				delete queryParams.search.isDeleted;
			}

			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				menuTemplateDataResponse = await fetchFn({...defaultQueryParams, limit}, 'menu-template');
			} else {
				menuTemplateDataResponse = await fetchFn(queryParams, 'menu-template');
			}
			setMenuTemplateData(menuTemplateDataResponse?.data);
			if (captureLatestUpdateTime && menuTemplateDataResponse?.data?.rows?.length) {
				const lastEditerData = menuTemplateDataResponse?.data.rows[0];
				setLatestUpdateInfo({
					updatedAt: lastEditerData?.updatedAt,
					updater: lastEditerData?.updater?.username,
				});
			}
			highlightNewData && setHighlightNewData(true);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const handleSort = dataIndex => {
		const queryParamsTemp = cloneDeep(queryParams);

		let sortOrderTemp = '';
		if (queryParamsTemp.sortBy === dataIndex) {
			switch (queryParams.sortOrder) {
			case null:
				sortOrderTemp = 'asc';
				break;
			case 'asc':
				sortOrderTemp = 'desc';
				break;
			case 'desc':
				if (dataIndex === 'createdAt') {
					sortOrderTemp = 'asc';
				} else {
					sortOrderTemp = null;
				}
				break;
			default:
				sortOrderTemp = null;
				break;
			}
		} else {
			sortOrderTemp = 'asc';
			queryParamsTemp.page = 0;
		}

		queryParamsTemp.sortOrder = sortOrderTemp;
		queryParamsTemp.sortBy = sortOrderTemp ? dataIndex : 'createdAt';

		setQueryParams(queryParamsTemp);
	};

	const sortTableHeader = (text, dataIndex) => (
		<div className='flex items-center justify-between'>
			<div>{text}</div>
			<FontAwesomeIcon
				onClick={() => handleSort(dataIndex)}
				className='cursor-pointer text-custom-grey-light hover:text-aqua-dark transition-colors duration-300'
				icon={
					dataIndex === queryParams.sortBy
						? queryParams.sortOrder === 'asc' ? faSortUp : faSortDown
						: faSort
				} />
		</div>
	);

	const handleRemove = async id => {
		const fetchFn = isDraft ? deleteMenuTemplateDraft : deleteMenuTemplate;
		const {success} = await fetchFn(id);
		if (success) {
			fetchTableData();
			notification.open({
				message: `${isDraft ? 'Delete': 'Archive'} Success`,
				description: `1 template ${isDraft ? 'draft' : ''} successfully ${isDraft ? 'deleted': 'archived'}.${isDraft ? '' : 'Check archived tab to view'}`,
				type: 'success',
			});
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const actionMenu = record => {
		return (
			<Menu>
				<Menu.Item>
					<a
						onClick={() => exportCSV(record.id)}
						className='text-antd-blue-6'>Export</a>
				</Menu.Item>
				<Menu.Item>
					<Popconfirm
						placement="topRight"
						title={`Are you sure want to ${isDraft ? 'delete': 'archive'} this menu template ?`}
						onConfirm={() => handleRemove(record.id)}
						okText="Yes"
						cancelText="No"
					>
						<a className='text-antd-blue-6'>{isDraft ? 'Delete' : 'Archive'}</a>
					</Popconfirm>
				</Menu.Item>
			</Menu>
		);
	};

	const openDetail = async record => {
		let stateToPass = {};

		if (!isDraft) {
			const response = await getMenuTemplateDetail({id: record.id});
			stateToPass = {
				menuTemplateState: response.data.details,
			};
		} else {
			stateToPass = {
				menuTemplateLabel: record.label,
				menuTemplateObj: record.obj,
				draftId: record.id,
			};
		}

		sessionStorage.setItem(STORAGE_KEY.MENU_TEMPLATE_STATE, JSON.stringify(stateToPass));
		window.open(`/sales-channel/menu-template/${isDraft ? 'new' : 'detail'}`, '_blank');
	};

	const handleUnarchive = item => {
		Modal.confirm({
			title: 'Unarchive this template?',
			content: 'The unarchived template will return to the list of available templates and can be used to make new requests.',
			okText: 'Unarchive',
			centered: true,
			onOk: async () => {
				try {
					setLoading(true);
					const response = await unarchiveTemplate(item.id);
					if (response.success) {
						notification.success({
							message: 'Unarchive success',
							description: 'Template has been successfully unarchived',
						});
						fetchTableData({reset: true});
						return;
					}
					throw {};
				} catch (error) {
					handleErrorFetch(error, {
						message: 'Unarchive failed',
						description: 'Template has failed to be unarchived',
					});
				} finally {
					setLoading(false);
				}
			},
		});
	};

	const columnsTemplate = [
		{
			title: tabsKey === 'draft' ? 'Draft ID' : 'Template ID',
			dataIndex: 'id',
			key: tabsKey === 'draft' ? 'templateId' : 'id',
			render: id => id === '-' ? <span>-</span> : <Paragraph copyable>{id}</Paragraph>,
		},
		{
			title: 'Template Name',
			dataIndex: 'label',
			key: 'label',
			render: label => label === '-' ? <span>-</span> : <Paragraph copyable>{label}</Paragraph>,
		},
		{
			title: 'Created By',
			dataIndex: ['creator', 'username'],
			key: 'createdBy',
		},
		{
			title: () => sortTableHeader('Created At', 'createdAt'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			search: false,
			render: createdAt => <div>{dateFormat(createdAt)}</div>,
		},
		{
			title: 'Archived By',
			dataIndex: ['deleter', 'username'],
			key: 'deletedBy',
		},
		{
			title: () => sortTableHeader('Archived At', 'deletedAt'),
			dataIndex: 'deletedAt',
			key: 'deletedAt',
			search: false,
			render: deletedAt => <div>{dateFormat(deletedAt)}</div>,
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			search: false,
			render: (_, record) => (
				<div className='flex justify-evenly text-blue-400 items-center'>
					{
						isArchived
							? (
								<div
									className='cursor-pointer'
									onClick={() => handleUnarchive(record)}
								>
									{'Unarchive'}
								</div>
							)
							: (
								<>
									<div
										className='cursor-pointer'
										onClick={() => openDetail(record)}
									>
										{isDraft ? 'Edit' : 'Detail'}
									</div>
									{
										authChecker({
											auth,
											requiredRolesArr: EDITOR_ROLES,
										})
											? (
												<>
													{
														(!isDraft && record.version === 1)
															? null
															:
															(
																<>
																	<Divider type='vertical' />
																	{
																		isDraft
																			? (
																				<div
																					onClick={() => openCreateModal({isCopy: true, payload: record,  useLatest: false})}
																					className='cursor-pointer' >
																					Copy
																				</div>
																			)
																			: (
																				<Dropdown
																					placement="bottomRight"
																					overlay={(
																						<Menu
																							items={[
																								{
																									onClick: () => openCreateModal({isCopy: true, payload: record, useLatest: true}),
																									key: 'useLatest',
																									label: 'Copy with latest masterlist',
																								},
																								{
																									onClick: () => openCreateModal({isCopy: true, payload: record,  useLatest: false}),
																									key: 'useExact',
																									label: 'Copy exact menu template',
																								},
																							]}
																						/>
																					)}>
																					<a
																						className='text-antd-blue-6'
																						onClick={e => e.preventDefault()}>
																						Copy{' '}
																						<DownOutlined />
																					</a>
																				</Dropdown>
																			)
																	}
																</>
															)
													}
													<Divider type='vertical' />
													<Dropdown
														trigger={['click']}
														overlay={() => actionMenu(record)}>
														<a>See More</a>
													</Dropdown>
												</>
											)
											: null
									}
								</>
							)
					}
				</div>
			),
		},
	];

	const getColumns = () => {
		const excludeFromActive = ['deletedAt', 'deletedBy'];
		const excludeFromArchive = [];
		let output = [];

		if (isDraft && !authChecker({
			auth,
			requiredRolesArr: EDITOR_ROLES,
		})) excludeFromActive.push('action');

		const excludedData = isArchived ? excludeFromArchive : excludeFromActive;

		columnsTemplate.forEach(item => {
			if (excludedData.every(el => el !== item.key)) output.push(item);
		});

		return output;
	};

	// End of Function Section

	// Component Lifecycle Section

	useEffect(() => {
		if (highlightNewData) {
			highlightNewDataTimeout = setTimeout(() => {
				setHighlightNewData(false);
			}, 2000);
		}
		return () => clearTimeout(highlightNewDataTimeout);
	}, [highlightNewData]);

	useEffect(() => {
		if (isArchived) return;
		refetchTableRef.current = fetchTableData;
		// getMenuTemplateDraft({queryParams});
	}, []);

	useEffect(() => {
		// To prevent api be called multiple times when active tabs are changed
		activeTabs === tabsKey && (async () => await fetchTableData({reset: true}))();
	}, [activeTabs]);

	useEffect(() => {
		if (resetRefFlag.current) return;
		(async () => await fetchTableData())();
	}, [search, page, sortOrder, limit]);

	// End of Component Lifecycle Section

	return (
		<div className='pb-8'>
			<ConfigProvider locale={enUS}>
				<ProTable
					rowKey='id'
					loading={loading}
					columns={getColumns()}
					dataSource={menuTemplateData?.rows}
					options={false}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: menuTemplateData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					search={{
						layout: 'vertical',
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);
						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					onReset={() => fetchTableData({reset: true})}
					className='transition-colors duration-1000'
					rowClassName={(record, index) => {
						const newDataCount = menuTemplateData?.count - previousMenuTemplateData?.count;
						const rowTableOrder = (index + 1) + (page * limit);
						if (rowTableOrder <= newDataCount && highlightNewData) return 'row-highlight transition-colors duration-1000';
					}}
				/>
			</ConfigProvider>
		</div>
	);
};

MenuTemplateTable.defaultProps = {
	isArchived: false,
	activeTabs: null,
	tabsKey: null,
	searchQuery: {},
	exportCSV: () => null,
	refetchTableRef: {},
	setLatestUpdateInfo: () => null,
	openCreateModal: () => null,
};

MenuTemplateTable.propTypes = {
	isArchived: PropTypes.bool,
	activeTabs: PropTypes.string,
	tabsKey: PropTypes.string,
	searchQuery: PropTypes.object,
	exportCSV: PropTypes.func,
	refetchTableRef: PropTypes.object,
	setLatestUpdateInfo: PropTypes.func,
	openCreateModal: PropTypes.func,
};

export default MenuTemplateTable;
