import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Button} from 'antd';
import LocalPageHeader from 'components/LocalPageHeader';
import FinanceAddOnTable from './FinanceAddOnTable';
import CreateModal from './CreateModal';

import {FormOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.InternalOrdering.FinanceAddOn;

const FinanceAddOn = ({type}) => {
	const [createModalOpen, setCreateModaOpen] = useState(false);
	const refetchTableRef = useRef(null);

	const componentLocale = type === 'campaign' ? locale.Campaign : locale.Product;

	return (
		<>
			<CreateModal
				open={createModalOpen}
				type={type}
				onClose={() => setCreateModaOpen(false)}
				refetchTableRef={refetchTableRef}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={componentLocale.title}
					customBreadcrumbLabel={componentLocale.title}
					extra={[
						<Button
							onClick={() => setCreateModaOpen(true)}
							key='create'
							icon={<FormOutlined />}
							type='primary'
						>{componentLocale.new}
						</Button>,
					]}
				/>
				<FinanceAddOnTable
					type={type}
					refetchTableRef={refetchTableRef}
				/>
			</div>
		</>
	);
};

FinanceAddOn.defaultProps = {
	type: null,
};

FinanceAddOn.propTypes = {
	type: PropTypes.oneOf(['campaign', 'product']),
};

export default FinanceAddOn;