import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getOrderTemplates = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/order-template/list`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getActiveMenuTemplate = async params => {
	try {

		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/menu-template`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewOrderTemplate = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/order-template/create`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editOrderTemplate = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/order-template/update`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteOrderTemplate = async payload => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/order/internal/order-template/delete`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getCampaignList = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/campaign/list`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getCampaignDetail = async id => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/campaign/${id}/details`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getOrderRequestList = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder, internalOrderCampaignId, requestStatus} = query;
		const params = {
			internalOrderCampaignId,
			requestStatus,
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/order/list`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewCampaign = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/campaign/create`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editCampaign = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/campaign/update`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteCampaign = async payload => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/order/internal/campaign/delete`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMapDetail = async gmapUrl => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/location/details`,
			params: {gmapUrl},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getAvailableLocation = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/location/available`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getClosestLocation = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/location/closest`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createOrderRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/order/create`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editOrderRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/order/update`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const submitOrderRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/order/submit`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const cancelOrderRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/order/cancel`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteOrderRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/order/internal/order/delete`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getCampaignNameList = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/master/campaigns`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getProductPushList = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/master/product-pushes`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editCampaignName = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/master/campaign`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editProductPush = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/master/product-push`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createCampaignName = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/master/campaign`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createProductPush = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/master/product-push`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getCampaignCodeList = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder, internalOrderCampaignId} = query;
		const params = {
			internalOrderCampaignId,
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/order/internal/master/campaign-code`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createCampaignCode = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/master/campaign-code`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const updateCampaignCode = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/order/internal/master/campaign-code`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const generateKolCodeHmac = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/internal/master/campaign-code-hmac`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};