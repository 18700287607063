import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Card, DatePicker, Input, Modal, notification, Radio, Select, Typography} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import {cloneDeep, debounce, isEmpty, isNumber} from 'lodash';
import clsx from 'clsx';
import {ExclamationCircleOutlined, FileDoneOutlined} from '@ant-design/icons';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';

// Local compoennts
import MenuCombinationSelectorModal from './MenuCombinationSelectorModal';
import ScheduleTimeField from './ScheduleTimeField';

// Global utils
import {detectUserTimezoneOffset, handleErrorFetch, removeEmptyFields} from 'utils/utils';
import {createOrderRequest, editOrderRequest, getAvailableLocation, getCampaignNameList, getMapDetail, getProductPushList} from 'utils/request/internalOrdering';
import {getOutletData} from 'utils/request/outlet';

// Request
import {INTERNAL_ORDERING_TYPE, KOL_TYPE, OUTLET_TYPE} from 'utils/constants';

const REGEX_PHONE_NUMBER = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,8}$/;
const REGEX_VALID_LINK = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const TIMEZONE = [
	{key: 7, label: 'WIB'},
	{key: 8, label: 'WITA'},
	{key: 9, label: 'WIT'},
];

const FORM_KEY = {
	INTERNAL_ORDER_CAMPAIGN_ID: 'internalOrderCampaignId',
	CUSTOMER_NAME: 'customerName',
	PHONE_NUMBER: 'phoneNumber',
	SCHEDULE_TIME: 'scheduleTime',
	ORDER_TEMPLATE_ID: 'orderTemplateId',
	DISCOUNT_PERCENTAGE: 'discountPercentage',
	LOCATION_ID: 'locationId',
	DELIVERY_METHOD: 'deliveryMethod',
	LATITUDE: 'latitude',
	LONGITUDE: 'longitude',
	ADDRESS: 'address',
	NOTES: 'notes',
	KOL_TYPE: 'kolType',
	BRAND_ID: 'brandId',
	CAMPAIGN_NAME_ID: 'campaignNameId',
	PRODUCT_PUSH_ID: 'productPushId',
	PERIOD: 'period',
};

import localization from 'localization';
const locale = localization.InternalOrdering.CampaignRequest;

const CampaignRequest = ({isEdit, isDetail}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	const menuCombinationFieldRef = useRef(null);
	const scheduleTimeFieldRef = useRef(null);
	const addressLinkFieldRef = useRef(null);

	const campaignId = params?.campaignId || null;
	const orderRequestId = params?.orderRequestId || null;
	const campaignType = location?.state?.campaignType || null;
	const orderType = location?.state?.orderType || null;
	const requestData = location?.state?.requestData || {};

	const isTakeaway = orderType == 'takeaway';
	const isBulk = campaignType == INTERNAL_ORDERING_TYPE.bulk.key;
	const isKOL = campaignType == INTERNAL_ORDERING_TYPE.kol.key;

	const [mapDataLoading, setMapDataLoading] = useState(false);
	const [mapData, setMapData] = useState({});

	const [outletListLoading, setOutletListLoading] = useState(false);
	const [outletList, setOutletList] = useState([]);

	const [brandList, setBrandList] = useState([]);

	const [menuCombinationModalOpen, setMenuCombinationModalOpen] = useState(false);

	const [showRequiredField, setShowRequiredField] = useState(false);
	const [selectedTimezone, setSelectedTimezone] = useState(null);

	const [loading, setLoading] = useState(false);
	const [requestDetails, setRequestDetails] = useState({
		internalOrderCampaignId: campaignId,
		customerName: null,
		phoneNumber: null,
		scheduleTime: null,
		orderTemplateId: null,
		type: campaignType,
		discountPercentage: null,
		locationId: null,
		orderType: orderType,
		kolType: null,
		deliveryDetails: {
			deliveryMethod: null,
			latitude: null,
			longitude: null,
			address: null,
			notes: null,
		},
		kolDetails: {
			brandId: null,
			campaignNameId: null,
			productPushId: null,
			period: null,
		},
	});
	const [menuCombinationInfo, setMenuCombinationInfo] = useState({
		id: null,
		label: null,
	});

	const [campaignNameList, setCampaignNameList] = useState([]);
	const [productPushList, setProductPushList] = useState([]);
	const [loadingSearchCampaignName, setLoadingSearchCampaignName] = useState(false);
	const [loadingSearchProductPush, setLoadingSearchProductPush] = useState(false);

	const selectedOutlet = outletList.find(outlet => outlet.id == requestDetails.locationId) || {};
	const validPhoneNumber = REGEX_PHONE_NUMBER.test(requestDetails?.phoneNumber);

	const showNearestOutlet = isKOL || !isTakeaway;

	const invalidDiscountAmount = isBulk &&
	requestDetails.discountPercentage !== null &&
	requestDetails.discountPercentage !== '' &&
	isNumber(Number(requestDetails.discountPercentage)) && (
		Number(requestDetails.discountPercentage) < 1 ||
    Number(requestDetails.discountPercentage) > 30
	);

	const getMapData = async inputtedVal => {
		try {
			const isValidLink = REGEX_VALID_LINK.test(inputtedVal);
			if (!isValidLink) return;
			setMapDataLoading(true);
			const response = await getMapDetail(inputtedVal);
			if (!isEmpty(response.data)) {
				setMapData(response.data);
				setRequestDetails(state => ({
					...state,
					deliveryDetails: {
						...state.deliveryDetails,
						...response.data,
					},
				}));
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setMapDataLoading(false);
		}
	};

	const getMapDataDebounce = useCallback(debounce(getMapData, 1000), []);

	const handleBack = () => {
		navigate(-1);
	};

	const getUTC0Time = momentInstance => {
		return momentInstance.utcOffset(0).subtract(Math.abs(selectedTimezone - detectUserTimezoneOffset()), 'hours').toISOString?.();
	};

	const fetchBrands = async () => {
		try {
			const response = await getOutletData({
				search: {limit: 0, isActive: true},
			}, OUTLET_TYPE.BRAND);
			if (response.success) {
				setBrandList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const fetchProductPush = async val => {
		try {
			if (loadingSearchProductPush) return;
			setLoadingSearchProductPush(true);
			const response = await getProductPushList({limit: 5, search: {label: val}});
			if (response.success) {
				setProductPushList(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingSearchProductPush(false);
		}
	};

	const searchProductPush = useCallback(
		debounce(val => fetchProductPush(val), 500),
		[],
	);

	const fetchCampaignName = async val => {
		try {
			if (loadingSearchCampaignName) return;
			setLoadingSearchCampaignName(true);
			const response = await getCampaignNameList({limit: 5, search: {label: val}});
			if (response.success) {
				setCampaignNameList(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingSearchCampaignName(false);
		}
	};

	const searchCampaignName = useCallback(
		debounce(val => fetchCampaignName(val), 500),
		[],
	);

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const callPayload = cloneDeep(requestDetails);

			if (!callPayload.discountPercentage) delete callPayload.discountPercentage;
			callPayload.scheduleTime = getUTC0Time(moment(requestDetails.scheduleTime));

			if (isEdit) callPayload.id = orderRequestId;
			if (isTakeaway) delete callPayload.deliveryDetails;
			if (isKOL) {
				const financeDetail = callPayload.kolDetails;
				for (const key in financeDetail) {
					if (financeDetail[key] === null) {
						throw {message: 'Some of the finance details are empty'};
					}
				}
				callPayload.kolDetails.period = moment(callPayload.kolDetails.period).format('YYYY-MM');
			} else {
				delete callPayload.kolDetails;
			}

			const callFn = isEdit ? editOrderRequest : createOrderRequest;

			removeEmptyFields(callPayload);

			const response = await callFn(callPayload);

			if (response.success) {
				notification.success({
					message: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].message,
					description: locale[`${isEdit ? 'Edit' : 'Create'}SuccessNotification`].description,
				});
				handleBack();
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].message,
				description: locale[`${isEdit ? 'Edit' : 'Create'}FailedNotification`].description,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleOnChangeRequestDetail = (itemKey, itemValue) => {
		if ((itemKey in requestDetails.deliveryDetails)) {
			setRequestDetails(state => ({
				...state,
				deliveryDetails: {
					...state.deliveryDetails,
					[itemKey]: itemValue,
				},
			}));
		} else if (itemKey in requestDetails.kolDetails) {
			setRequestDetails(state => ({
				...state,
				kolDetails: {
					...state.kolDetails,
					[itemKey]: itemValue,
				},
			}));

		} else {
			setRequestDetails(state => ({
				...state,
				[itemKey]: itemValue,
			}));
		}
		// if (isTakeaway) {
		// 	setRequestDetails(state => ({
		// 		...state,
		// 		[itemKey]: itemValue,
		// 	}));
		// } else {
		// 	if ((itemKey in requestDetails.deliveryDetails)) {
		// 		setRequestDetails(state => ({
		// 			...state,
		// 			deliveryDetails: {
		// 				...state.deliveryDetails,
		// 				[itemKey]: itemValue,
		// 			},
		// 		}));
		// 	} else {
		// 		setRequestDetails(state => ({
		// 			...state,
		// 			[itemKey]: itemValue,
		// 		}));
		// 	}
		// }
	};

	const BottomAction = () => {
		return (
			<div className='flex justify-end gap-2'>
				<Button
					disabled={loading}
					onClick={() => {
						isDetail
							? handleBack()
							: Modal.confirm({
								title: locale.BackConfirmation.title,
								content: locale.BackConfirmation.content,
								cancelText: locale.BackConfirmation.cancelText,
								okText: locale.BackConfirmation.okText,
								icon: <ExclamationCircleOutlined />,
								centered: true,
								maskClosable: true,
								onOk: handleBack,
							});
					}}>{isDetail ? locale.close : locale.cancel}</Button>
				{
					!isDetail && (
						<Button
							loading={loading}
							onClick={() => {
								Modal.confirm({
									title: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].title,
									content: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].content,
									cancelText: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].cancelText,
									okText: locale[`${isEdit ? 'Edit' : 'Create'}Confirmation`].okText,
									icon: <ExclamationCircleOutlined />,
									centered: true,
									maskClosable: true,
									onOk: handleSubmit,
								});
							}}
							type='primary'>{isEdit ? locale.save : locale.createRequest}
						</Button>
					)
				}
			</div>
		);
	};

	const OutletItemForm = () => (
		<div className='flex flex-col gap-2'>
			<div>{locale.outlet}</div>
			<div>
				<Select
					status={
						isEmpty(selectedOutlet)
							? null
							: selectedOutlet?.slot == 0 && 'warning'
					}
					loading={outletListLoading}
					className='w-full'
					showSearch={!showNearestOutlet}
					onDropdownVisibleChange={val => val && onOpenOutletList()}
					filterOption={(input, option) => {
						const filteredLocation = outletList
							.filter(item => item.label.toLowerCase().includes(input.toLowerCase()))
							.map(item => item.id);

						return filteredLocation.includes(option.value);
					}}
					disabled={isDetail || outletListLoading}
					placeholder={locale.outletPlaceholder}
					value={requestDetails[FORM_KEY.LOCATION_ID]}
					onChange={val => handleOnChangeRequestDetail(FORM_KEY.LOCATION_ID, val)}
					options={outletList.map(outlet => {
						return {
							value: Number(outlet.id),
							label: (
								<div className='flex gap-2 items-center'>
									<div>{`${outlet?.label}${(showNearestOutlet && !isTakeaway) ? outlet?.distance ? ` (${outlet?.distance})` : ' -'  : ''}`}</div>
									{
										(isKOL) && (
											<div className={clsx(
												'rounded-full flex justify-center items-center h-5 w-5',
												outlet?.slot > 0
													? 'bg-antd-green-1 text-antd-green-6 border border-antd-green-3'
													: 'bg-antd-gold-1 text-antd-gold-6 border border-antd-gold-3',
											)}>
												<div className='text-xs'>
													{outlet?.slot}
												</div>
											</div>
										)
									}
								</div>
							),
						};
					})}
				>
				</Select>
				{
					(isKOL)
						? !isEmpty(selectedOutlet) && selectedOutlet?.slot <= 0
							? <div className='text-antd-gold-6'>{locale.outletSlotExceeded}</div>
							: (
								<div className='flex'>
									<div className='text-antd-green-6 mr-1'>•</div>
									<div className='opacity-50'>{locale.slotAvailable}</div>
									<div className='mr-2'>,</div>
									<div className='text-antd-warning-6 mr-1'>•</div>
									<div className='opacity-50'>{locale.slotUnavailable}</div>
								</div>
							)
						: null
				}
			</div>
		</div>
	);

	const getOutletBySlotting = async () => {
		const tempParams = {
			orderTemplateId: requestDetails.orderTemplateId,
			type: campaignType,
			scheduleTime: moment(requestDetails.scheduleTime, 'YYYY MMM DD - HH:mm').format('YYYY-MM-DD'),
		};

		if (!isEmpty(mapData)) {
			tempParams.latitude = mapData.latitude;
			tempParams.longitude = mapData.longitude;
		}

		if (isBulk) tempParams.isBulk = true;

		const response = await getAvailableLocation(tempParams);
		return response.data;
	};

	const getDefaultLocation = async () => {
		const params = {limit: 0};
		if (isBulk) params.search = {allowBulk: true};
		const response = await getOutletData(params, OUTLET_TYPE.LOCATION);
		return response?.data?.rows?.sort((a, b) => a.label.localeCompare(b.label));
	};

	const getOutletList = async () => {
		try {
			setOutletListLoading(true);

			const callFn = showNearestOutlet ? getOutletBySlotting : getDefaultLocation;

			const outletListTemp = await callFn();
			setOutletList(outletListTemp);

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setOutletListLoading(false);
		}
	};

	const handleSelectMenuCombination = selectedMenuCombination => {
		setMenuCombinationModalOpen(false);
		handleOnChangeRequestDetail(FORM_KEY.ORDER_TEMPLATE_ID, selectedMenuCombination?.id);
		setMenuCombinationInfo({
			id: selectedMenuCombination?.id,
			label: selectedMenuCombination?.name,
		});
	};

	const handlePopulateForm = () => {
		setOutletList([{
			label: requestData?.Location?.label,
			id: requestData?.locationId,
			slot: requestData?.slot,
		}]);

		const requestDetailPayload = {
			internalOrderCampaignId: campaignId,
			customerName: requestData?.customerName,
			phoneNumber: requestData?.phoneNumber,
			kolType: requestData?.kolType,
			scheduleTime: moment(requestData?.scheduleTime),
			orderTemplateId: requestData?.orderTemplateId,
			type: campaignType,
			discountPercentage: requestData?.discountPercentage || null,
			locationId: requestData?.locationId,
			orderType: orderType,
			deliveryDetails: requestData?.deliveryDetails,
		};

		if (isKOL) {
			setCampaignNameList([{
				id: requestData?.kolDetails?.campaignNameId,
				label: requestData?.campaignName,
			}]);
			setProductPushList([{
				id: requestData?.kolDetails?.productPushId,
				label: requestData?.productPush,
			}]);

			requestDetailPayload.kolDetails = {
				brandId: requestData?.kolDetails?.brandId || null,
				campaignNameId: requestData?.kolDetails?.campaignNameId || null,
				productPushId: requestData?.kolDetails?.productPushId || null,
				period: requestData?.kolDetails?.period
					? moment(requestData?.kolDetails?.period)
					: null,
			};
		}

		setRequestDetails(requestDetailPayload);

		setMenuCombinationInfo({
			id: requestData?.orderTemplateId,
			label: requestData?.OrderTemplate?.name,
		});
	};

	const onOpenOutletList = () => {
		if (showNearestOutlet) {
			if (!requestDetails[FORM_KEY.ORDER_TEMPLATE_ID]) {
				menuCombinationFieldRef.current.scrollIntoView({behavior: 'smooth'});
				return setShowRequiredField(true);
			}
			if (!requestDetails[FORM_KEY.SCHEDULE_TIME]) {
				scheduleTimeFieldRef?.current?.scrollIntoView?.({behavior: 'smooth'});
				return setShowRequiredField(true);
			}
			if (!isTakeaway && isEmpty(mapData)) {
				addressLinkFieldRef.current.scrollIntoView({behavior: 'smooth'});
				return setShowRequiredField(true);
			}
		}
	};

	const fetchFinanceDetails = async () => {
		try {
			await fetchBrands();
			await fetchProductPush();
			await fetchCampaignName();
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		if (!showNearestOutlet) return;
		if (requestDetails.scheduleTime && requestDetails.orderTemplateId) {
			if (!isTakeaway && isEmpty(mapData)) return;
			getOutletList();
		}
	}, [requestDetails.scheduleTime, requestDetails.orderTemplateId, mapData]);

	useEffect(() => {
		setSelectedTimezone(detectUserTimezoneOffset());
		if (requestDetails.locationId) {
			const selectedLocationStillExist = outletList.find(outlet => outlet.id == requestDetails.locationId);
			if (!selectedLocationStillExist) handleOnChangeRequestDetail(FORM_KEY.LOCATION_ID, null);
		}
	}, [outletList]);

	useEffect(() => {
		if (!orderType) return handleBack();
		if (isKOL) fetchFinanceDetails();
		if (isEdit || isDetail) handlePopulateForm();
		if (!showNearestOutlet) getOutletList();
	}, []);

	return (
		<>
			<MenuCombinationSelectorModal
				open={menuCombinationModalOpen}
				onClose={() => setMenuCombinationModalOpen(false)}
				onSelect={handleSelectMenuCombination}
			/>
			<div className='bg-white h-full overflow-auto pb-12'>
				<LocalPageHeader
					headerTitle={
						(isEdit || isDetail)
							? `${orderType} ∙ ${orderRequestId?.toUpperCase?.()}`
							: locale.title.replace('{{type}}', campaignType == INTERNAL_ORDERING_TYPE.kol.key ? 'Marketing' : campaignType)
					}
				/>
				<div className='px-6'>
					<div className='flex flex-col gap-6'>
						{/* Finance Detail */}
						{isKOL && (
							<Card
								title={locale.financeDetail}
							>
								<div className='flex flex-col gap-6 max-w-xs'>
									{/* Campaign Name */}
									<div className='flex flex-col gap-2'>
										<div>{locale.campaignName}</div>
										<Select
											showSearch
											loading={loadingSearchCampaignName}
											placeholder={locale.campaignNamePlaceholder}
											optionFilterProp="label"
											value={requestDetails.kolDetails[FORM_KEY.CAMPAIGN_NAME_ID]}
											onChange={val => handleOnChangeRequestDetail(FORM_KEY.CAMPAIGN_NAME_ID, val)}
											onSearch={searchCampaignName}
											options={campaignNameList?.map?.(campaignName => {
												return {
													value: campaignName.id,
													label: campaignName.label,
												};
											})}
										/>
									</div>

									{/* Brand */}
									<div className='flex flex-col gap-2'>
										<div>{locale.brand}</div>
										<Select
											showSearch
											placeholder={locale.brandPlaceholder}
											optionFilterProp="label"
											value={requestDetails.kolDetails[FORM_KEY.BRAND_ID]}
											onChange={val => handleOnChangeRequestDetail(FORM_KEY.BRAND_ID, val)}
											options={brandList?.map?.(brand => {
												return {
													value: brand.id,
													label: brand.label,
												};
											})}
										/>
									</div>

									{/* Product Push */}
									<div className='flex flex-col gap-2'>
										<div>{locale.productPush}</div>
										<Select
											showSearch
											loading={loadingSearchProductPush}
											placeholder={locale.productPushPlaceholder}
											optionFilterProp="label"
											value={requestDetails.kolDetails[FORM_KEY.PRODUCT_PUSH_ID]}
											onChange={val => handleOnChangeRequestDetail(FORM_KEY.PRODUCT_PUSH_ID, val)}
											onSearch={searchProductPush}
											options={productPushList?.map?.(productPush => {
												return {
													value: productPush.id,
													label: productPush.label,
												};
											})}
										/>
									</div>

									{/* Period */}
									<div className='flex flex-col gap-2'>
										<div>{locale.period}</div>
										<DatePicker
											format='YYYY-MM'
											picker='month'
											placeholder={locale.periodPlaceholder}
											value={
												moment.isMoment(requestDetails.kolDetails[FORM_KEY.PERIOD])
													? moment(requestDetails.kolDetails[FORM_KEY.PERIOD], 'YYYY-MM')
													: null
											}
											onChange={val => {
												if (moment.isMoment(val)) {
													handleOnChangeRequestDetail(
														FORM_KEY.PERIOD,
														val,
													);
												} else {
													handleOnChangeRequestDetail(FORM_KEY.PERIOD, null);
												}
											}}
										/>
									</div>
								</div>
							</Card>
						)}

						{/* Request Detail */}
						<Card
							title={locale.requestDetail}
						>
							<div className='flex flex-col gap-6 max-w-xs'>

								{/* Menu Combination */}
								<div
									ref={menuCombinationFieldRef}
									className='flex flex-col gap-2'>
									{
										menuCombinationInfo.id
											? (
												<>
													<Typography.Text type='secondary'>
														{locale.menuCombination}
													</Typography.Text>
													<div className='flex flex-col gap-2'>
														<div className='flex gap-2 items-center'>
															<FileDoneOutlined />
															<div className='flex gap-1'>
																<span>{`${menuCombinationInfo.label}`}</span>
																<span className='opacity-40 uppercase'>{`[${menuCombinationInfo.id}]`}</span>
															</div>
														</div>
														{
															!isDetail && (
																<div
																	onClick={() => setMenuCombinationModalOpen(true)}
																	className='text-antd-blue-6 cursor-pointer'>{locale.change}
																</div>
															)
														}
													</div>
												</>
											)
											: (
												<>
													<div>{locale.menuCombination}</div>
													<Button
														onClick={() => setMenuCombinationModalOpen(true)}
														type='primary'>{locale.selectMenuCombination}</Button>
													{(showRequiredField && !requestDetails[FORM_KEY.ORDER_TEMPLATE_ID]) && <div className='text-antd-red-6'>{locale.menuCombinationRequired}</div>}
												</>
											)
									}
								</div>

								{/* KOL Type */}
								{
									isKOL && (
										<div className='flex flex-col gap-2'>
											<div>{locale.kolType}</div>
											<Select
												disabled={isDetail}
												value={requestDetails[FORM_KEY.KOL_TYPE]}
												onChange={e => handleOnChangeRequestDetail(FORM_KEY.KOL_TYPE, e)}
												placeholder={locale.kolTypePlaceholder}
												options={Object.keys(KOL_TYPE).map(kolType => {
													return {
														label: KOL_TYPE[kolType],
														value: kolType,
													};
												})}
											/>
										</div>
									)
								}

								{/* Discount Amount */}
								{
									isBulk && (
										<div className='flex flex-col gap-2'>
											<div>{locale.discountPercentage}</div>
											<div>
												<Input
													type='number'
													disabled={isDetail}
													value={requestDetails[FORM_KEY.DISCOUNT_PERCENTAGE]}
													placeholder={locale.discountPercentagePlaceholder}
													onChange={e =>handleOnChangeRequestDetail(FORM_KEY.DISCOUNT_PERCENTAGE, e.target.value)}
												/>
												{invalidDiscountAmount && <span className='text-antd-red-6'>{locale.discountPercentageError}</span>}
											</div>
										</div>
									)
								}

								{/* Customer Name */}
								<div className='flex flex-col gap-2'>
									<div>{locale.customerName}</div>
									<Input
										disabled={isDetail}
										value={requestDetails[FORM_KEY.CUSTOMER_NAME]}
										onChange={e => handleOnChangeRequestDetail(FORM_KEY.CUSTOMER_NAME, e.target.value)}
										placeholder={locale.customerNamePlaceholder} />
								</div>

								{/* Phone Number */}
								<div className='flex flex-col gap-2'>
									<div>{locale.phoneNumber}</div>
									<div className='flex flex-col'>
										<Input
											disabled={isDetail}
											value={requestDetails[FORM_KEY.PHONE_NUMBER]}
											onChange={e => handleOnChangeRequestDetail(FORM_KEY.PHONE_NUMBER, e.target.value)}
											className='w-full'
											placeholder={locale.phoneNumberPlaceholder} />
										{
											(requestDetails[FORM_KEY.PHONE_NUMBER] && !validPhoneNumber) && (
												<span className='text-antd-red-6'>{locale.phoneNumberError}</span>
											)
										}
									</div>
								</div>

								{isTakeaway && (
									<>
										<ScheduleTimeField
											TIMEZONE={TIMEZONE}
											scheduleTimeFieldRef={scheduleTimeFieldRef}
											showRequiredField={showRequiredField}
											requestDetails={requestDetails}
											FORM_KEY={FORM_KEY}
											isDetail={isDetail}
											selectedTimezone={selectedTimezone}
											setSelectedTimezone={setSelectedTimezone}
											handleOnChangeRequestDetail={handleOnChangeRequestDetail}
											locale={locale}
										/>
										<OutletItemForm />
									</>
								)}

							</div>

							{isTakeaway && <BottomAction />}
						</Card>

						{/* Delivery Detail */}
						{
							!isTakeaway && (
								<Card title={locale.deliveryDetail}>
									<div className='flex flex-col gap-6 max-w-xs'>

										<ScheduleTimeField
											TIMEZONE={TIMEZONE}
											scheduleTimeFieldRef={scheduleTimeFieldRef}
											showRequiredField={showRequiredField}
											requestDetails={requestDetails}
											FORM_KEY={FORM_KEY}
											isDetail={isDetail}
											selectedTimezone={selectedTimezone}
											setSelectedTimezone={setSelectedTimezone}
											handleOnChangeRequestDetail={handleOnChangeRequestDetail}
											locale={locale}
										/>

										{/* Address Link */}
										<div
											ref={addressLinkFieldRef}
											className='flex flex-col gap-2'>
											<div>{locale.addressLink}</div>
											<div>
												<Input
													disabled={mapDataLoading || isDetail}
													placeholder={locale.addressLinkPlaceholder}
													onChange={e => getMapDataDebounce(e.target.value)}
												/>
												<span className='opacity-40'>{locale.addressLinkInfo}</span>
											</div>
											{
												(
													<>
														<Input.TextArea
															disabled={mapDataLoading || isDetail}
															status={(showRequiredField && !requestDetails?.deliveryDetails?.address) && 'error'}
															autoSize={{
																minRows: 2,
																maxRows: 5,
															}}
															placeholder={locale.addressDetailPlaceholder}
															onChange={e => handleOnChangeRequestDetail(FORM_KEY.ADDRESS, e.target.value)}
															value={requestDetails?.deliveryDetails?.address}
														/>
														{
															(showRequiredField && !requestDetails?.deliveryDetails?.address) &&<span className='text-antd-red-6'>{locale.addressLinkRequired}</span>
														}
													</>
												)
											}
										</div>

										{/* Driver Notes */}
										<div className='flex flex-col gap-2'>
											<div>{locale.driverNotes}</div>
											<Input.TextArea
												showCount
												maxLength={150}
												placeholder={locale.driverNotesPlaceholder}
												onChange={e => handleOnChangeRequestDetail(FORM_KEY.NOTES, e.target.value)}
												value={requestDetails.deliveryDetails[FORM_KEY.NOTES]}
												disabled={isDetail}
											/>
										</div>

										{/* Vehicle */}
										<div className='flex flex-col gap-2'>
											<div>{locale.vehicle}</div>
											<div>
												<Radio.Group
													disabled={isDetail}
													value={requestDetails.deliveryDetails[FORM_KEY.DELIVERY_METHOD]}
													onChange={e => handleOnChangeRequestDetail(FORM_KEY.DELIVERY_METHOD, e.target.value)}
												>
													<Radio
														key='bike'
														value='bike'>Motorcycle</Radio>
													<Radio
														key='car'
														value='car'>Car</Radio>
												</Radio.Group>
											</div>
										</div>

										{/* Outlet */}
										<OutletItemForm />

									</div>
									<BottomAction />
								</Card>
							)
						}
					</div>
				</div>
			</div>
		</>
	);
};

CampaignRequest.defaultProps = {
	isEdit: false,
	isDetail: false,
};

CampaignRequest.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
};

export default CampaignRequest;