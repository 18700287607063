import ProTable from '@ant-design/pro-table';
import {Divider, Modal, notification, Typography} from 'antd';
import localization from 'localization';
import {isEmpty, isObject} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {deleteCampaign, getCampaignList} from 'utils/request/internalOrdering';
import {dateFormat, handleErrorFetch, sortDirectionConverter} from 'utils/utils';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {REQUEST_TABS} from 'utils/constants';

const CampaignListTable = ({editCampaignConfirmation, refetchTableRef}) => {
	const tableRef = useRef(null);
	const locale = localization.InternalOrdering.OrderCreator;
	const navigate = useNavigate();

	const [loadingDelete, setLoadingDelete] = useState(false);

	const cantDeleteConfirmation = () => {
		Modal.confirm({
			title: locale.Table.CantDeleteConfirmation.title,
			icon: <ExclamationCircleOutlined />,
			content: locale.Table.CantDeleteConfirmation.content,
			centered: true,
			okText: locale.Table.CantDeleteConfirmation.okText,
			cancelButtonProps: {style: {display: 'none'}},
		});
	};

	const deleteCofirmation = (campaignId, campaignName) => {
		Modal.confirm({
			title: locale.Table.DeleteConfirmation.title,
			icon: <ExclamationCircleOutlined />,
			content: locale.Table.DeleteConfirmation.content.replace('{{campaignName}}', campaignName),
			centered: true,
			cancelText: locale.Table.DeleteConfirmation.cancelText,
			okText: locale.Table.DeleteConfirmation.okText,
			okButtonProps: {danger: true, loading: loadingDelete},
			onOk: async () => {
				try {
					setLoadingDelete(true);
					const response = await deleteCampaign({id: campaignId});
					if (response.success) {
						notification.success({
							message: locale.Table.DeleteSuccessNotification.message,
							description: locale.Table.DeleteSuccessNotification.description,
						});
					}
					tableRef?.current?.reload?.();
				} catch (error) {
					handleErrorFetch(error);
				} finally {
					setLoadingDelete(false);
				}
			},
		});
	};

	const openCampaignDetail = (campaign, focusTab) => {
		const canUseCode = !isEmpty(campaign?.kolDetails);
		const focusTabTemp = isEmpty(focusTab) ? canUseCode
			? REQUEST_TABS.KOL_CODE
			: REQUEST_TABS.DRAFT
			: focusTab;
		navigate(`/internal-ordering/order-creator/campaign-detail/${campaign.id}?tab=${focusTabTemp}`);
	};

	const columns = [
		{
			title: locale.Table.campaignId,
			width: 200,
			key: 'id',
			dataIndex: 'id',
			render: id => (
				<Typography.Text
					copyable
				>{id?.toUpperCase?.()}</Typography.Text>
			),
		},
		{
			title: locale.Table.campaignName,
			width: 222,
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: locale.Table.requestStatus,
			width: 159,
			align: 'center',
			key: 'requestStatus',
			valueEnum: {
				draft: locale.Table.draftRequest,
				active: locale.Table.activeRequest,
			},
			render: (_, record) => (
				<div className='flex flex-row gap-2 justify-center items-center'>
					<div
						className='text-antd-blue-6 cursor-pointer'
						onClick={() => openCampaignDetail(record, REQUEST_TABS.DRAFT)}
					>{locale.Table.draftCount.replace('{{count}}', record.pendingCount || 0)}</div>
					<Divider
						type='vertical'
						className='m-0 p-0' />
					<div
						className='text-antd-blue-6 cursor-pointer'
						onClick={() => openCampaignDetail(record, REQUEST_TABS.ACTIVE)}
					>{locale.Table.activeCount.replace('{{count}}', record.activeCount || 0)}</div>
				</div>
			),
		},
		{
			title: 'KOL Seeding Campaign',
			width: 222,
			key: 'kolDetails',
			dataIndex: 'kolDetails',
			render: kolDetails => {
				const isKolSeeding = isObject(kolDetails) && !isEmpty(kolDetails);
				return (
					<div className={`font-semibold ${isKolSeeding ? 'text-green-400' : 'text-red-400'}`}>
						{isKolSeeding ? 'Yes' : 'No'}
					</div>
				);
			},
		},
		{
			title: locale.Table.createdBy,
			width: 155,
			key: 'createdBy',
			dataIndex: ['creator', 'username'],
		},
		{
			title: locale.Table.createdAt,
			width: 195,
			key: 'createdAt',
			dataIndex: 'createdAt',
			sorter: true,
			defaultSortOrder: 'descend',
			search: false,
			render: createdAt => dateFormat(createdAt),
		},
		{
			title: locale.Table.editedBy,
			width: 155,
			key: 'updatedBy',
			search: false,
			dataIndex: ['updater', 'username'],
		},
		{
			title: locale.Table.editedAt,
			width: 195,
			key: 'updatedAt',
			sorter: true,
			dataIndex: 'updatedAt',
			search: false,
			render: updatedAt => updatedAt ? dateFormat(updatedAt) : '-',
		},
		{
			title: locale.Table.action,
			width: 197,
			key: 'action',
			search: false,
			fixed: 'right',
			align: 'center',
			render: (_, record) => (
				<div className='flex gap-2 justify-center items-center'>
					<a
						className='text-antd-blue-6'
						onClick={() => editCampaignConfirmation(record)}
					>{locale.Table.edit}</a>
					<Divider
						className='m-0'
						type='vertical' />
					<a
						onClick={() => openCampaignDetail(record)}
						className='text-antd-blue-6'
					>{locale.Table.detail}</a>
					<Divider
						className='m-0'
						type='vertical' />
					<a
						onClick={() => record?.activeCount
							? cantDeleteConfirmation()
							: deleteCofirmation(record.id, record.name)}
						className='text-antd-red-6'
					>{locale.Table.delete}</a>
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const sortOrderKey = isEmpty(sorter) ? 'descend' : Object.values?.(sorter)?.[0];
			const sortByKey = isEmpty(sorter) ? 'createdAt' : Object.keys?.(sorter)?.[0];

			const payload = {
				limit: params?.pageSize,
				page: params?.current - 1,
				sortBy: sortByKey,
				sortOrder: sortDirectionConverter(sortOrderKey),
				search: {
					id: params?.id?.toLowerCase?.() || null,
					name: params?.name || null,
					createdBy: params?.createdBy || null,
					requestStatus: params?.requestStatus || null,
					types: 'KOL',
				},
			};
			const response = await getCampaignList(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		refetchTableRef.current = tableRef?.current?.reload;
	}, []);

	return (
		<div>
			<ProTable
				loading={loadingDelete}
				actionRef={tableRef}
				className="mt-4 ResetSearchTableMargin px-6"
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				onReset={() => tableRef?.current?.reset()}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
};

CampaignListTable.defaultProps = {
	editCampaignConfirmation: () => null,
	refetchTableRef: null,
};

CampaignListTable.propTypes = {
	editCampaignConfirmation: PropTypes.func,
	refetchTableRef: PropTypes.object,
};

export default CampaignListTable;