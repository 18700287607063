import {PROMO_CATEGORY, PROMO_TYPE, USER_ROLES} from 'utils/constants';
import {authChecker, dateFormat, moneyFormat} from 'utils/utils';

import moment from 'moment';
import startCase from 'lodash/startCase';

import {Badge, DatePicker, Divider, Input, Popconfirm, Select, Tag, Tooltip, Typography} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

const {Paragraph} = Typography;
const {RangePicker} = DatePicker;
const {Option} = Select;

const EDITOR_ROLES = [USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.EDITOR];

const PROMO_STATUS = {
	ACTIVE: 'active',
	SCHEDULED: 'scheduled',
	EXPIRED: 'expired',
	INACTIVE: 'inactive',
	PENDING: 'pending',
	SUCCESS: 'success',
	ERROR: 'error',
	RESYNC: 'resync',
	FAILED: 'failed',
	CANCELLED: 'cancelled',
	COMPLETED: 'completed',
};

const TABLE_CONTENT = {
	[PROMO_CATEGORY.EXTERNAL]: [
		'campaignRequestId', 'id', 'campaignLabel', 'discountAmount', 'brandLabel',
		'menuId', 'menuLabel', 'platformLabel', 'locationId', 'startDate', 'endDate',
		'tags', 'templateType', 'status', 'action',
	],
	[PROMO_CATEGORY.INTERNAL]: [
		'promoRequestId', 'id', 'promoLabel', 'brandLabel', 'platformLabel',
		'locationId', 'startDate', 'endDate', 'status', 'action',
	],
};

const decideSyncStatusColor = status => {
	let badgeColor;
	switch (status) {
	case PROMO_STATUS.SCHEDULED:
	case PROMO_STATUS.PENDING:
		badgeColor = 'processing';
		break;
	case PROMO_STATUS.ACTIVE:
	case PROMO_STATUS.SUCCESS:
	case PROMO_STATUS.COMPLETED:
		badgeColor = 'success';
		break;
	case PROMO_STATUS.EXPIRED:
		badgeColor = 'warning';
		break;
	case PROMO_STATUS.INACTIVE:
	case PROMO_STATUS.CANCELLED:
		badgeColor = 'default';
		break;
	case PROMO_STATUS.ERROR:
	case PROMO_STATUS.FAILED:
		badgeColor = 'error';
		break;
	case PROMO_STATUS.RESYNC:
		badgeColor = 'orange';
		break;
	default:
		badgeColor = 'processing';
		break;
	}
	return (<div className='capitalize'><Badge status={badgeColor} /> {status}</div>);
};

export default (config = {}) => {
	const {
		promoCategory = null,
		handleResync = () => null,
		handleCancel = () => null,
		handleCancelSync = () => null,
		handleToggleInternalPromo = () => null,
		location = [],
		brandList = [],
		platformList = [],
		locationLoading = false,
		debounceFn = () => null,
		auth = {},
	} = config;

	const isInternal = promoCategory === PROMO_CATEGORY.INTERNAL;

	const externalAction = record => (
		<div className='flex flex-col gap-1 items-center text-antd-blue-6 justify-center'>
			{
				record.status === PROMO_STATUS.ERROR && (
					<div className='flex items-center'>
						<Popconfirm
							title='Are you sure want to resync this request?'
							onConfirm={() => handleResync(record?.id)}
							okText="Yes"
							cancelText="No"
							placement='topRight'
						>
							<a>Re-Sync</a>
						</Popconfirm>
						<Divider type='vertical' />
						<Popconfirm
							title='Are you sure want to cancel this request?'
							onConfirm={() => handleCancel(record?.id)}
							okText="Yes"
							cancelText="No"
							placement='topRight'
						>
							<a>Cancel</a>
						</Popconfirm>
					</div>
				)
			}
			{
				[PROMO_STATUS.ACTIVE, PROMO_STATUS.SUCCESS].some(status => status === record.status) && (
					<Popconfirm
						title='Are you sure want to terminate this request?'
						onConfirm={() => handleCancelSync(record?.id)}
						okText="Yes"
						cancelText="No"
						placement='topRight'
					>
						<a>Terminate</a>
					</Popconfirm>
				)
			}
			{
				PROMO_STATUS.SCHEDULED === record.status && (
					<Popconfirm
						title='Are you sure want to cancel this request?'
						onConfirm={() => handleCancel(record?.id)}
						okText="Yes"
						cancelText="No"
						placement='topRight'
					>
						<a>Cancel</a>
					</Popconfirm>
				)
			}
		</div>
	);

	const internalAction = record => (
		<div className='flex flex-col gap-1 items-center text-antd-blue-6 justify-center'>
			{
				[PROMO_STATUS.PENDING, PROMO_STATUS.ACTIVE].some(status => status === record.status) && (
					<Popconfirm
						title='Are you sure want to inactivate this promo?'
						onConfirm={() => handleToggleInternalPromo({
							updateId: [record.id],
							updateTo: 'inactive',
						})}
						okText="Yes"
						cancelText="No"
						placement='topRight'
					>
						<a>Inactivate</a>
					</Popconfirm>
				)
			}
			{
				PROMO_STATUS.INACTIVE === record.status && (
					<Popconfirm
						title='Are you sure want to activate this promo?'
						onConfirm={() => handleToggleInternalPromo({
							updateId: [record.id],
							updateTo: 'active',
						})}
						okText="Yes"
						cancelText="No"
						placement='topRight'
					>
						<a>Activate</a>
					</Popconfirm>
				)
			}
		</div>
	);

	const columnTemplate = [
		{
			title: 'Request ID',
			dataIndex: isInternal ? 'promoRequestId' : 'campaignRequestId',
			key: isInternal ? 'promoRequestId' : 'campaignRequestId',
			width: 100,
			render: campaignRequestId => (
				<div className='flex items-center'>RQ-<Paragraph
					className='m-0'
					copyable>{campaignRequestId}</Paragraph></div>
			),
			renderFormItem: () => (
				<Input
					addonBefore='RQ-'
				/>
			),
		},
		{
			title: 'Promo ID',
			dataIndex: 'id',
			key: 'id',
			search: false,
			render: id => (
				<div className='flex items-center'>PI-<Paragraph
					className='m-0'
					copyable>{id}</Paragraph></div>
			),
		},
		{
			title: 'Promo Name',
			dataIndex: isInternal ? ['PromoRequest', 'label'] : 'campaignLabel',
			key: isInternal ? 'promoLabel' : 'campaignLabel',
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			key: 'startDate',
			renderFormItem: () => (
				<RangePicker
					ranges={{
						Today: [moment(), moment()],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
					}}
					placeholder={['From', 'Until']}
				/>
			),
			render: startDate => (
				<div>{dateFormat(startDate, true)}</div>
			),
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			key: 'endDate',
			renderFormItem: () => (
				<DatePicker />
			),
			render: endDate => (
				<div>{dateFormat(endDate, true)}</div>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			fixed: 'right',
			onCell: () => (<div />),
			renderFormItem: () => {
				const promoStatusArray = isInternal
					? [PROMO_STATUS.ACTIVE, PROMO_STATUS.INACTIVE, PROMO_STATUS.EXPIRED, PROMO_STATUS.PENDING]
					: Object.values(PROMO_STATUS);
				return (
					<Select
						allowClear
						showSearch
						className='capitalize'
						placeholder='Please select'>
						{
							promoStatusArray?.sort?.()?.map(status => (
								<Option
									value={status}
									key={status}
									className="capitalize" >{status}</Option>
							))
						}
					</Select>
				);
			},
			render: (status, record) => (
				<div className='flex gap-2 items-center'>
					{decideSyncStatusColor(status)}
					{
						(status === PROMO_STATUS.FAILED || status === PROMO_STATUS.ERROR) && record?.details?.errMessage &&  (
							<Tooltip
								title={record?.details?.errMessage}
							>
								<InfoCircleOutlined className='text-antd-red-5 cursor-pointer' />
							</Tooltip>
						)
					}
					{
						(status === PROMO_STATUS.SCHEDULED) && record?.UpdateMenu?.id && (
							<Tooltip
								title={`Approval ID: ${record?.syncRequestId?.id} (${record?.UpdateMenu?.status})`}
							>
								<InfoCircleOutlined className='text-antd-warning-6 cursor-pointer' />
							</Tooltip>
						)
					}
				</div>
			),
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			search: false,
			fixed: 'right',
			width: 180,
			render: (_, record) => isInternal ? internalAction(record): externalAction(record),
		},
		{
			title: 'Discount',
			dataIndex: ['campaignDetails', 'details', 'value'],
			key: 'discountAmount',
			search: false,
			render: (_, record) => {
				const discountAmount = record?.campaignDetails?.details?.value;
				const discountType = record?.campaignDetails?.details?.type;
				return (
					<div>
						{discountType === 'net' ? moneyFormat({value: discountAmount}) : discountAmount}
						{discountType === 'percentage' && '%'}
					</div>
				);
			},
		},
		{
			title: 'Brand',
			dataIndex: [...(isInternal ? ['Merchant'] : []), 'Brand', 'label'],
			key: 'brandLabel',
			renderFormItem: () => (
				<Select
					showSearch
					placeholder='Please select'>
					{
						brandList
							?.filter(brand => brand?.isActive)
							?.sort((a, b) => a.label.localeCompare(b.label))
							?.map(brand => (
								<Option
									key={brand.id}
									value={brand.label}>{brand.label}</Option>
							))
					}
				</Select>
			),
		},
		{
			title: 'Menu ID',
			dataIndex: ['campaignDetails', 'menus', '0', 'menuId'],
			key: 'menuId',
		},
		{
			title: 'Menu Name',
			dataIndex: ['campaignDetails', 'menus', '0', 'menuLabel'],
			key: 'menuLabel',
		},
		{
			title: 'Sales Channel',
			dataIndex: [...(isInternal ? ['Merchant'] : []), 'Platform', 'label'],
			key: 'platformLabel',
			renderFormItem: () => (
				<Select
					showSearch
					placeholder='Please select'>
					{
						platformList?.sort((a, b) => a.label.localeCompare(b.label))?.map(platform => (
							<Option
								key={platform.id}
								value={platform.label}>{platform.label}</Option>
						))
					}
				</Select>
			),
		},
		{
			title: 'Outlet',
			dataIndex: [...(isInternal ? ['Merchant'] : []), 'Location', 'label'],
			key: 'locationId',
			renderFormItem: () => (
				<Select
					allowClear
					showSearch
					loading={locationLoading}
					onSearch={debounceFn}
					filterOption={((_, option) => option)}
				>
					{location.map(location => (
						<Select.Option
							key={location.id}
							value={location.id}>{location.label}</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Tags',
			dataIndex: 'tags',
			key: 'tags',
			renderFormItem: () => (
				<Select
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			),
			render: tags => (Array.isArray(tags) && tags.length) ? (
				<div className='flex flex-wrap gap-y-2'>
					{tags.map((tag, index) => (
						<Tag
							color='blue'
							className='uppercase'
							key={index}>{tag}
						</Tag>
					))}
				</div>
			) : '-',
		},
		{
			title: 'Promo Type',
			dataIndex: 'templateType',
			key: 'templateType',
			renderFormItem: () => (
				<Select placeholder='Please select'>
					{
						Object.values(PROMO_TYPE).map(promoType => (
							<Option
								key={promoType}
								value={promoType}>{startCase(promoType)}</Option>
						))
					}
				</Select>
			),
			render: templateType => (
				<div className='capitalize'>{templateType} Discount</div>
			),
		},
	];

	let output = [];

	TABLE_CONTENT[promoCategory].forEach(column => {
		columnTemplate.forEach(content => {
			if (content.key === column) output.push(content);
		});
	});

	if (!authChecker({
		auth,
		requiredRolesArr: [...(isInternal ? [USER_ROLES.RESTOADMIN] : []), ...EDITOR_ROLES],
		children: true,
	})) output.pop();

	return output;
};