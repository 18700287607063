export const RELEASE_STAGE = process.env.REACT_APP_RELEASE_STAGE || 'development';

const isProductionBuild = RELEASE_STAGE === 'production';

export const URLS = {
	ORDERHUB_BASE_URL: isProductionBuild
		? 'https://api.ishangry.com/orderhub'
		: 'https://api-dev.ishangry.com/orderhub',
	ISHANGRY_BASE_URL: isProductionBuild
		? 'https://www.ishangry.com'
		: 'https://next-dev.ishangry.com',

};

export const MASTERLIST_TYPE = {
	CATEGORY: 'category',
	MENU: 'menu',
	CHILD_MENU: 'child-menu',
	OPTION_GROUP: 'option-group',
	MATERIAL: 'material',
	MENU_STRUCTURE: 'menu-structure',
	REMINDER: 'menu-reminder',
};

export const OUTLET_TYPE = {
	LOCATION: 'location',
	BRAND: 'brand',
	MERCHANT: 'outlet',
	BRANCH: 'branch',
	MDR: 'mdr',
	COMPANY: 'company',
	POS_USER: 'pos_user',
};

export const PARENT_MAPPING_OPTIONS = {
	MENU: 'menu',
	COMPLEX_RULE: 'complexMenu',
	OPTION_GROUP: 'optionGroups',
	NONE: 'none',
};

export const AUTH = {
	ACCESS_TOKEN: 'access_token',
};

export const IMAGE_SIZE = {
	MAX_IMAGE_SIZE: 5120000,
	MAX_SIZE_MB: '5MB',
};

export const CLOUDINARY_DIR = {
	MENU_IMAGE: 'orderhub-master_menus',
	BRAND: 'orderhub-brand',
};

export const STORAGE_KEY = {
	MASTERLIST_ITEM_DETAIL: 'itemDetail',
	MASTERLIST_MENU_REQUEST_DETAIL: 'menuSyncRequestDetail',
	REDIRECT_TO_PATH: 'redirectToPath',
	MENU_TEMPLATE_STATE: 'menuTemplateState',
};

export const OUTLET_USER_MODAL_ACTION_TYPE = {
	CREATE: 'create',
	RESET: 'reset',
};

export const USER_ROLES = {
	SUPERADMIN: 'superadmin',
	DBADMIN: 'db_admin',
	RESTOADMIN: 'resto_admin',
	MASTERLIST: {
		ADMIN: 'masterlist_admin',
		VIEWER: 'masterlist_viewer',
		EDITOR: 'masterlist_editor',
	},
	MENU_TEMPLATE: {
		ADMIN: 'menu_template_admin',
		VIEWER: 'menu_template_viewer',
		EDITOR: 'menu_template_editor',
	},
	SYNC_MENU: {
		ADMIN: 'sync_menu_admin',
		VIEWER: 'sync_menu_viewer',
		APPROVER: 'sync_menu_approver',
		EDITOR: 'sync_menu_editor',
	},
	SYNC_PROMO: {
		ADMIN: 'sync_promo_admin',
		VIEWER: 'sync_promo_viewer',
		APPROVER: 'sync_promo_approver',
		EDITOR: 'sync_promo_editor',
	},
	OUTLET: {
		ADMIN: 'outlet_admin',
		VIEWER: 'outlet_viewer',
		EDITOR: 'outlet_editor',
	},
	USER: {
		ADMIN: 'user_admin',
		VIEWER: 'user_viewer',
		EDITOR: 'user_editor',
	},
	WATCHTOWER: {
		ADMIN: 'watchtower_admin',
		ORDER_ADMIN: 'watchtower_order_admin',
		MENU_ADMIN: 'watchtower_menu_admin',
		PROMO_ADMIN: 'watchtower_promo_admin',
	},
	FINANCE: {
		EDITOR: 'finance_editor',
		VIEWER: 'finance_viewer',
	},
	TRAINER: {
		ADMIN: 'trainer_admin',
	},
	MARKETING: {
		EDITOR: 'marketing_editor',
		VIEWER: 'marketing_viewer',
	},
	POS: {
		PIC: 'pos_pic',
		CASHIER: 'pos_cashier',
		SM: 'pos_sm',
	},
	INTERNAL_ORDERING: {
		INTERNAL_ORDER_CAMPAIGN_EDITOR_BULK: 'internal_order_campaign_editor_bulk',
		INTERNAL_ORDER_CAMPAIGN_EDITOR_KOL: 'internal_order_campaign_editor_kol',
		INTERNAL_ORDER_CAMPAIGN_EDITOR_REPLACEMENT: 'internal_order_campaign_editor_replacement',
	},
};

export const ORDER_MERCHANT_STATUS_CONST = {
	ACCEPTED: {value: 'accepted', label: 'Accepted'},
	DRIVER_FOUND: {value: 'driverFound', label: 'Driver Found'},
	DRIVER_ARRIVED: {value: 'driverArrived', label: 'Driver Arrived'},
	COLLECTED: {value: 'collected', label: 'Collected'},
	DELIVERED: {value: 'delivered', label: 'Delivered'},
	FAILED: {value: 'failed', label: 'Failed'},
	CANCELLED: {value: 'cancelled', label: 'Cancelled'},
	DELETED: {value: 'deleted', label: 'Deleted'},
};

export const PROMO_CATEGORY = {
	INTERNAL: 'free-item',
	EXTERNAL: 'price-cut',
};

export const INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE = {
	SKU: 'sku',
	FREE_ITEM: 'freeItem',
};

export const REFETCH_MAPPING_REQUEST = 'refetch_mapping_request';
export const REFETCH_CAMPAIGN_LIST_TABLE = 'refetchCampaignListTable';
export const USE_LATEST_MENU_COMBINATION = 'useLatestMenuCombination';
export const REFETCH_PROMO_REQUEST = 'refetchPromoRequest';

export const PROMO_TYPE = {
	SINGLE: 'single',
	MULTIPLE: 'multiple',
	FLASH_SALE: 'flash',
};

export const AVAILABLE_POS = {
	ESB: {
		id: 1,
		label: 'ESB',
	},
	ODOO: {
		id: 2,
		label: 'Odoo',
	},
};

export const MENU_STATION = {
	KITCHEN: 'kitchen',
	BAR: 'bar',
	NONE: '',
};

export const POS_PLATFORM_TYPE = {
	INTERNAL: 'internal',
	EXTERNAL: 'external',
};

export const POS_ORDER_STATUS = {
	PENDING: 'pending',
	PROCESSED: 'processed',
	DONE: 'done',
	VOID: 'void',
	CANCEL: 'cancelled',
};

export const POS_PAIR_STATUS = {
	PENDING: 'pending',
	PENDING_LATER: 'pending_later',
	DONE: 'done',
	MERGED: 'merged',
	VOID: 'void',
};

export const POS_PAIR_METHOD = {
	AUTO: 'auto',
	MANUAL: 'manual',
	FORCE: 'force',
};

export const PAYMENT_METHOD_ACTION_TYPE = [
	{value: 'none', label: 'None'},
	{value: 'traceNumber', label: 'Trace Number'},
	{value: 'other', label: 'Another Info'},
];

export const PACKAGING_OPTIONS = {
	DEFAULT: 'child',
	SEPARATE: 'separate',
	HIDE: 'hide',
	LINK: 'link',
};

export const INTERNAL_ORDERING_TYPE = {
	kol: {
		key: 'KOL',
		label: 'Marketing',
	},
	replacement: {
		key: 'replacement',
		label: 'Replacement',
	},
	bulk: {
		key: 'bulk',
		label: 'Bulk',
	},
};

export const KOL_TYPE = {
	paid_kol: 'Paid KOL',
	seeding_kol: 'Seeding KOL',
	bulk_partnership: 'Bulk Community/Partnership',
	bulk_marketing: 'Bulk Marketing',
	non_bulk_marketing: 'Non-Bulk Marketing',
};

export const REQUEST_TABS = {
	KOL_CODE: 'kolCode',
	DRAFT: 'pending',
	ACTIVE: 'active',
	DONE: 'done',
};

export const CO_LABEL_PAYLOAD = 'coLabelPayload';

export const EMAIL_PROVIDER = '@ishangry.com';