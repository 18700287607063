import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {get, isEmpty} from 'lodash';
import moment from 'moment';

// Package
import {Alert, Button, Dropdown, notification, Spin, Statistic, Tabs} from 'antd';
import {EditOutlined, FormOutlined} from '@ant-design/icons';

// Components
import SaveConfirmationModal from 'pages/InternalOrdering/MenuCreator/MenuCreatorForm/SaveConfirmationModal';
import LocalPageHeader from 'components/LocalPageHeader';
import RequestListTable from './RequestListTable';
import GenerateCodeModal from './GenerateCodeModal';

// Utils
import {handleErrorFetch} from 'utils/utils';
import {editCampaign, getCampaignDetail} from 'utils/request/internalOrdering';
import {INTERNAL_ORDERING_TYPE, REFETCH_CAMPAIGN_LIST_TABLE, REQUEST_TABS} from 'utils/constants';

import localization from 'localization';
const locale = localization.InternalOrdering.CampaignDetail;
const menuCreatorFormLocale = localization.InternalOrdering.MenuCreatorForm;

const CAMPAIGN_TYPE = [
	{
		key: 'delivery',
		label: locale.delivery,
	},
	{
		key: 'takeaway',
		label: locale.takeaway,
	},
];

const CampaignDetail = ({internalOrderType}) => {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();

	const searchInfo = Object.fromEntries(new URLSearchParams(location.search).entries());

	const isKol = internalOrderType == INTERNAL_ORDERING_TYPE.kol.key;
	const campaignId = isKol ? params?.campaignId || null : internalOrderType;

	const [loading, setLoading] = useState(false);
	const [campaignData, setCampaignData] = useState({});
	const kolDetails = get(campaignData, 'kolDetails', {});

	const canUseCode = !isEmpty(kolDetails);

	const [loadingSave, setLoadingSave] = useState(false);
	const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] = useState(false);
	const [generateCodeModalOpen, setGenerateCodeModalOpen] = useState(false);

	const dropdownItems = CAMPAIGN_TYPE.map(type => {
		return {
			...type,
			onClick: () => navigate(`/internal-ordering/order-creator/campaign-detail/${campaignId}/new`, {
				state: {
					orderType: type.key,
					campaignType: internalOrderType || campaignData?.type,
				},
			}),
		};
	});

	const initialFetch = async () => {
		try {
			setLoading(true);
			const response = await getCampaignDetail(campaignId);
			if (response.success) {
				setCampaignData(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const refreshDetailAndList = () => {
		initialFetch();
		window.postMessage(REFETCH_CAMPAIGN_LIST_TABLE);
	};

	const handleSaveMenuCombination = async menuCombinationName => {
		try {
			setLoadingSave(true);

			const payload = {
				id: campaignId,
				name: menuCombinationName,
			};

			const response = await editCampaign(payload);

			if (response.success) {
				notification.success({
					message: menuCreatorFormLocale['EditSuccessNotification'].message,
					description: menuCreatorFormLocale['EditSuccessNotification'].description,
				});
				refreshDetailAndList();
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: menuCreatorFormLocale['EditFailedNotification'].message,
				description: menuCreatorFormLocale['EditFailedNotification'].description,
			});
		} finally {
			setLoadingSave(false);
			setSaveConfirmationModalOpen(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, [campaignId]);

	return (
		<>
			<GenerateCodeModal
				campaignId={campaignId}
				refresh={refreshDetailAndList}
				open={generateCodeModalOpen}
				onCancel={() => setGenerateCodeModalOpen(false)}
			/>
			<SaveConfirmationModal
				open={saveConfirmationModalOpen}
				onSave={handleSaveMenuCombination}
				loadingSave={false}
				onClose={() => !loadingSave && setSaveConfirmationModalOpen(false)}
				isEdit
				currentMenuCombinationName={campaignData?.name}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					showBackButton={isKol}
					capitalize={isKol ? false : true}
					headerTitle={isKol ? campaignData?.name : `${internalOrderType} Order Request`}
					extra={[
						...(isKol ? [<Button
							onClick={() => setSaveConfirmationModalOpen(true)}
							icon={<EditOutlined />}
							key='edit'>{locale.edit}
						</Button>] : []),
						<Dropdown
							trigger={['click']}
							menu={{items: dropdownItems}}
							key='create'>
							<Button
								icon={<FormOutlined />}
								type='primary'
								key='new'>{locale.create}
							</Button>
						</Dropdown>,
					]}
				/>
				{
					loading
						? (
							<div className='flex justify-center items-center mt-5'>
								<Spin size='large' />
							</div>
						)
						: (
							<div className='flex flex-col gap-4 pb-12'>
								{
									canUseCode && (
										<div className='px-6 flex flex-col gap-2'>
											<div className='grid grid-cols-4'>
												<Statistic
													title="Campaign End Date"
													value={moment(campaignData?.campaignEndDate).format('DD MMM YYYY')}
												/>
												<Statistic
													title="Menu Combination"
													value={campaignData?.OrderTemplate?.name}
												/>
											</div>
											<div className='grid grid-cols-4'>
												<Statistic
													title="Campaign Name"
													value={campaignData?.campaignName?.label}
												/>
												<Statistic
													title="Brand"
													value={campaignData?.brand?.label}
												/>
												<Statistic
													title="Product Push"
													value={campaignData?.productPush?.label}
												/>
												<Statistic
													title="Period"
													value={moment(campaignData?.kolDetails?.period).format('MM-YYYY')}
												/>
											</div>
										</div>
									)
								}
								<Tabs
									onChange={tabVal => {
										navigate(`?tab=${tabVal}`);
									}}
									defaultActiveKey={searchInfo?.tab || null}
									tabBarStyle={{paddingInline: '24px'}}
								>
									{
										Object.values(REQUEST_TABS)
											.filter(tabKey => tabKey !== REQUEST_TABS.KOL_CODE || canUseCode)
											.map(tabKey => (
												<Tabs.TabPane
													key={tabKey}
													tab={(
														<div>
															{locale[`${tabKey}Request`]}
															<span className='ml-1 px-2 bg-antd-blue-1 text-antd-blue-6 rounded-lg'>
																{campaignData[`${tabKey}Count`] || 0}
															</span>
														</div>
													)}>
													{tabKey === REQUEST_TABS.KOL_CODE && (
														<div className='mx-6 flex justify-end'>
															<Button
																disabled={moment(campaignData?.campaignEndDate).isSameOrBefore(moment(), 'day')}
																onClick={() => setGenerateCodeModalOpen(true)}>Generate Code</Button>
														</div>
													)}
													{tabKey === REQUEST_TABS.ACTIVE && (
														<Alert
															className='mb-4 mx-6'
															message={locale.activeRequestInfo}
															type={'info'}
															showIcon />
													)}
													<div className='px-6'>
														<RequestListTable
															canUseCode={canUseCode}
															campaignId={campaignId}
															refreshDetailAndList={refreshDetailAndList}
															tabKey={tabKey}
															campaignType={internalOrderType}
														/>
													</div>
												</Tabs.TabPane>
											))
									}
								</Tabs>
							</div>
						)
				}
			</div>
		</>
	);
};

CampaignDetail.defaultProps = {
	internalOrderType: null,
};

CampaignDetail.propTypes = {
	internalOrderType: PropTypes.string,
};

export default CampaignDetail;