import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal, Input, Table, Popconfirm, notification, Typography} from 'antd';
import {getMasterlistData} from 'utils/request/masterlist';
import {getMenuDetailsFromMenuTemplate} from 'utils/request/promo';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import localization from 'localization';
import {handleErrorFetch, moneyFormat} from 'utils/utils';

const {Text} = Typography;
const {Search} = Input;

const locale = localization.Promo.CreateNewPromo.Form.MenuTemplateModal;

const defaultQueryParams = {
	search: {
		label: null,
		isDeleted: false,
	},
	page: 0,
	sortBy: 'updatedAt',
	sortOrder: 'desc',
	limit: 5,
};

const MenuTemplateModal = ({
	visible, close, setMenuTemplate, menuTemplateSelected, setMenuFromMenuTemplate, selectedMenus, setSelectedMenus,
}) => {

	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const {search, page} = queryParams;

	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);

	const resetRefFlag = useRef(false);

	const unSyncMenuColumn = [
		{
			title: 'Menu ID',
			dataIndex: 'menuId',
			key: 'menuId',
			width: 100,
		},
		{
			title: 'Menu Label',
			dataIndex: 'menuLabel',
			key: 'menuLabel',
			render: item => (
				<Text
					ellipsis={{tooltip: item}}
				>
					{item}
				</Text>
			),
		},
		{
			title: 'Menu Price',
			dataIndex: 'menuPrice',
			key: 'menuPrice',
			width: 150,
			render: item => <div>{moneyFormat({value: item})}</div>,
		},
	];

	const handleSelectMenuTemplate = async record => {
		try {
			setLoading(true);
			const menuDetails = await getMenuDetailsFromMenuTemplate(record.id);
			setLoading(false);

			if (menuDetails.success) {

				const updateMenuTemplate = () => {
					setMenuFromMenuTemplate(menuDetails.data);
					setMenuTemplate(record);

					if (menuTemplateSelected) {
						notification.open({
							message: locale.Changed.title,
							description: locale.Changed.description,
							type: 'success',
						});
					}
					close();
				};

				if (selectedMenus.length) {
					let tempInvalidSelectedMenu = [];

					/**
					 * If selected menus exist, loop through each selected menu to verify if the menu also exist in newly selected template.
					 *
					 * If the selected menu doesnt exist in newly selected template, tell the user which menu doest exist
					 * and those menu will be removed from selected menus if the user choose to proceed using the newly selected template.
					 */

					selectedMenus.forEach(selectedMenu => {
						// Validation for menuid and menu price
						const menuSource = menuDetails.data.menus.find(menu => menu.menuId == selectedMenu.menuId);

						if (!menuSource || menuSource.menuPrice != selectedMenu.menuPrice) {
							tempInvalidSelectedMenu.push(selectedMenu);
						}
					});

					if (tempInvalidSelectedMenu.length) {
						Modal.confirm({
							width: 800,
							title: locale.UnsyncMenuModal.title,
							icon: <ExclamationCircleOutlined />,
							content: (
								<React.Fragment>
									<div className='pb-4'>
										{locale.UnsyncMenuModal.description}
									</div>
									<Table
										dataSource={tempInvalidSelectedMenu}
										columns={unSyncMenuColumn}
										pagination={false}
										scroll={{
											y: 240,
										  }}
									/>
								</React.Fragment>
							),
							okText: 'Proceed',
							onOk() {
								const tempSelectedMenu = selectedMenus.filter(menu => !tempInvalidSelectedMenu.some(invalidMenu => invalidMenu.menuId == menu.menuId));
								setSelectedMenus(tempSelectedMenu);
								updateMenuTemplate();
							},
						  });
					} else {
						updateMenuTemplate();
					}
				} else {
					updateMenuTemplate();
				}

			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const columns = [
		{
			title: 'Menu Template ID',
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
		},
		{
			title: 'Menu Template Name',
			dataIndex: 'label',
			key: 'label',
			ellipsis: true,
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			render: (action, record) =>
				menuTemplateSelected
					? (
						<Popconfirm
							title={locale.Confirmation.description}
							placement="topRight"
							onConfirm={() => handleSelectMenuTemplate(record)}
						>
							<a className="text-center text-antd-blue-6">{locale.action}</a>
						</Popconfirm>
					)
					: (
						<div className="text-center">
							<a
								className='text-antd-blue-6'
								onClick={() => handleSelectMenuTemplate(record)}
							>
								{locale.action}
							</a>
						</div>
					),
		},
	];

	const fetchData = async (config = {
		reset: false,
	}) => {
		try {
			const {reset} = config;
			setLoading(true);

			let response = null;

			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit: 5});
				response = await getMasterlistData({...defaultQueryParams, limit: 5}, 'menu-template');
			} else {
				response = await getMasterlistData(queryParams, 'menu-template');
			}

			if (response.success) {
				setTableData(response.data);
			} else throw {};

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
			resetRefFlag.current = false;
		}
	};

	const searchTemplateList = value => {
		const tempQueryParams = {...queryParams, page: 0};

		value && value.length
			? tempQueryParams.search = {...search, label: value}
			: tempQueryParams.search = {...search, label: null};

		setQueryParams(tempQueryParams);
	};

	const debouncedChangeHandler = useCallback(
		debounce(searchTemplateList, 500)
		, []);

	const handleChange = pagers => {
		setQueryParams({...queryParams, page: pagers.current - 1});
	};

	useEffect(() => {
		if (!visible) return;
		fetchData({reset: true});
	}, [visible]);

	useEffect(() => {
		// To handle query changes
		if (resetRefFlag.current) return;
		(async () => await fetchData())();
	}, [search, page]);

	return (
		<Modal
			destroyOnClose
			visible={visible}
			title={locale.title}
			footer={null}
			onCancel={close}
			width={870}
			centered
		>
			<div className='flex flex-col gap-4'>
				<Search
					loading={loading}
					onChange={e => debouncedChangeHandler(e.target.value)}
					placeholder={locale.searchPlaceholder}
					className='w-1/3'
				/>

				<Table
					loading={loading}
					dataSource={tableData?.rows}
					columns={columns}
					pagination={{
						defaultPageSize: 5,
						total: tableData?.count,
						current: page + 1,
					}}
					onChange={handleChange}
				/>

			</div>
		</Modal>
	);
};

MenuTemplateModal.defaultProps = {
	visible: false,
	close: () => null,
	setMenuTemplate: () => null,
	menuTemplateSelected: false,
	setMenuFromMenuTemplate: () => null,
	selectedMenus: [],
	setSelectedMenus: () => null,
};

MenuTemplateModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	setMenuTemplate: PropTypes.func,
	menuTemplateSelected: PropTypes.bool,
	setMenuFromMenuTemplate: PropTypes.func,
	selectedMenus: PropTypes.array,
	setSelectedMenus: PropTypes.func,
};

export default MenuTemplateModal;
