import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Typography} from 'antd';
import {FileDoneOutlined} from '@ant-design/icons';
import MenuCombinationSelectorModal from '../CampaignRequest/MenuCombinationSelectorModal';

const CampaignModalMenuCombination = ({getter, setter}) => {
	const [menuCombinationModalOpen, setMenuCombinationModalOpen] = useState(false);
	const handleSelectMenuCombination = selectedMenuCombination => {
		setMenuCombinationModalOpen(false);
		setter(selectedMenuCombination);
	};
	return (
		<>
			<MenuCombinationSelectorModal
				open={menuCombinationModalOpen}
				onClose={() => setMenuCombinationModalOpen(false)}
				onSelect={handleSelectMenuCombination}
				definedCampaignType='KOL'
			/>
			<div
				className='flex flex-col gap-2 max-w-xs'>
				{
					getter.id
						? (
							<>
								<Typography.Text type='secondary'>
									{'Menu Combination'}
								</Typography.Text>
								<div className='flex flex-col gap-2'>
									<div className='flex gap-2 items-center'>
										<FileDoneOutlined />
										<div className='flex gap-1'>
											<span>{`${getter.name}`}</span>
											<span className='opacity-40 uppercase'>{`[${getter.id}]`}</span>
										</div>
									</div>
									{
										(
											<div
												onClick={() => setMenuCombinationModalOpen(true)}
												className='text-antd-blue-6 cursor-pointer'>{'Change'}
											</div>
										)
									}
								</div>
							</>
						)
						: (
							<>
								<div>{'Menu Combination'}</div>
								<Button
									onClick={() => setMenuCombinationModalOpen(true)}
									type='primary'>{'Select Menu Combination'}</Button>
							</>
						)
				}
			</div>
		</>
	);
};

CampaignModalMenuCombination.defaultProps = {
	getter: {},
	setter: () => null,
};

CampaignModalMenuCombination.propTypes = {
	getter: PropTypes.object,
	setter: PropTypes.func,
};

export default CampaignModalMenuCombination;