import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';
import {Modal, Steps} from 'antd';
import {CreditCardOutlined, FormOutlined, RestOutlined} from '@ant-design/icons';
import {isMoment} from 'moment';

import CampaignModalDefault from './CampaignModalDefault';
import CampaignModalFinanceDetail from './FinanceDetail';
import CampaignModalMenuCombination from './MenuCombination';
import {isEmpty} from 'lodash';

const financeDetailInitialState = {
	campaignNameId: null,
	brandId: null,
	productPushId: null,
	period: null,
};

const CampaignModal = ({open, onSave, loading, onClose, isEdit, campaignData}) => {
	const locale = isEdit
		? localization.InternalOrdering.OrderCreator.EditCampaignModal
		: localization.InternalOrdering.OrderCreator.NewCampaignModal;

	const [inputVal, setInputVal] = useState('');
	const [campaignForKolSeeding, setCampaignForKolSeeding] = useState(false);
	const [campaignEndDate, setCampaignEndDate] = useState(null);

	const [financeDetails, setFinanceDetails] = useState(financeDetailInitialState);

	const [selectedMenuCombination, setSelectedMenuCombination] = useState({});

	const [formStep, setFormStep] = useState(0);

	const renderForm = () => {
		switch (formStep) {
		case 0:
			return (
				<CampaignModalDefault
					isEdit={isEdit}
					loading={loading}
					inputVal={inputVal}
					setInputVal={setInputVal}
					campaignForKolSeeding={campaignForKolSeeding}
					setCampaignForKolSeeding={setCampaignForKolSeeding}
					campaignEndDate={campaignEndDate}
					setCampaignEndDate={setCampaignEndDate}
				/>
			);
		case 1:
			return (
				<CampaignModalFinanceDetail
					getter={financeDetails}
					setter={setFinanceDetails}
					isActive={formStep == 1}
				/>
			);
		case 2:
			return (
				<CampaignModalMenuCombination
					getter={selectedMenuCombination}
					setter={setSelectedMenuCombination}
				/>
			);
		default:
			return null;
		}
	};

	const theresStillNextStep = campaignForKolSeeding && formStep <= 1;

	const onOk = () => {
		if (theresStillNextStep) {
			setFormStep(formStep + 1);
		} else {
			onSave(inputVal, {
				campaignForKolSeeding,
				campaignEndDate,
				financeDetails: {
					...financeDetails,
					period: financeDetails.period?.format('YYYY-MM'),
				},
				selectedMenuCombination,
			});
		}
	};

	const okBtnDisabled = () => {
		switch (formStep) {
		case 0:
			if (campaignForKolSeeding) {
				return !(inputVal.length > 0 && isMoment(campaignEndDate));
			}
			return inputVal.length === 0;
		case 1:
			return (
				!financeDetails.campaignNameId ||
        !financeDetails.brandId ||
        !financeDetails.productPushId ||
        !financeDetails.period ||
        !isMoment(financeDetails.period)
			);
		case 2:
			return isEmpty(selectedMenuCombination);
		default:
			return true;
		}
	};

	useEffect(() => {
		if (open) {
			setFormStep(0);
			setInputVal(campaignData?.name || '');
			setCampaignForKolSeeding(false);
			setSelectedMenuCombination({});
			setFinanceDetails(financeDetailInitialState);
		}
	}, [open]);

	useEffect(() => {
		setCampaignEndDate(null);
	}, [campaignForKolSeeding]);

	return (
		<Modal
			closable={false}
			open={open}
			centered
			width={600}
			destroyOnClose
			onCancel={() => !loading && onClose()}
			okText={theresStillNextStep ? 'Next' : locale.okText}
			onOk={onOk}
			cancelText={locale.cancelText}
			okButtonProps={{
				disabled: okBtnDisabled(),
				loading: loading,
			}}
			cancelButtonProps={{
				loading: loading,
			}}
		>
			<div className='pb-2'>
				<Steps
					current={formStep}
					items={[
						{
							title: 'Campaign',
							icon: <FormOutlined />,
						},
						...(campaignForKolSeeding ? [
							{
								title: 'Finance Detail',
								icon: <CreditCardOutlined />,
							},
							{
								title: 'Menu Combination',
								icon: <RestOutlined />,
							},
						] : []),
					]}
				/>
			</div>
			{renderForm()}
		</Modal>
	);
};

CampaignModal.defaultProps = {
	open: false,
	onSave: () => null,
	loading: false,
	onClose: () => null,
	isEdit: false,
	campaignData: {},
};

CampaignModal.propTypes = {
	open: PropTypes.bool,
	onSave: PropTypes.func,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	isEdit: PropTypes.bool,
	campaignData: PropTypes.object,
};

export default CampaignModal;