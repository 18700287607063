import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {InputNumber, Modal, notification, Typography} from 'antd';
import {handleErrorFetch} from 'utils/utils';
import {createCampaignCode} from 'utils/request/internalOrdering';

const GenerateCodeModal = ({open, onCancel, campaignId, refresh}) => {

	const [codeQty, setCodeQty] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleCancel = () => !loading && onCancel();

	const onOk = async () => {
		try {
			setLoading(true);
			const response = await createCampaignCode({
				internalOrderCampaignId: campaignId,
				quantity: codeQty,
			});
			if (response.success) {
				notification.success({
					message: `${codeQty} has been generated for this campaign`,
				});
				refresh();
				onCancel();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (open) {
			setCodeQty(null);
			setLoading(false);
		}
	}, [open]);

	return (
		<Modal
			open={open}
			onCancel={handleCancel}
			centered
			okButtonProps={{loading, disabled: !codeQty}}
			width={480}
			onOk={onOk}
			okText='Generate'
		>
			<div>
				<Typography.Title level={5}>Generate Code</Typography.Title>
				<div className='flex flex-col gap-2'>
					<Typography.Text
						type='secondary'>Input quantity of code you would like to generate</Typography.Text>
					<InputNumber
						min={0}
						keyboard
						value={codeQty}
						onChange={val => setCodeQty(val)}
						formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
						parser={value => value.replace(/\./g, '')}
						style={{width: '100%'}}
					/>
				</div>
			</div>
		</Modal>
	);
};

GenerateCodeModal.defaultProps = {
	open: false,
	onCancel: () => null,
	refresh: () => null,
	campaignId: null,
};

GenerateCodeModal.propTypes = {
	open: PropTypes.bool,
	onCancel: PropTypes.func,
	refresh: PropTypes.func,
	campaignId: PropTypes.string,
};

export default GenerateCodeModal;